import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { PatientService } from "../../api/patient/patient.service";
import { catchError, map, mergeMap } from "rxjs/operators";
import { getAllPatientsRequest, getAllPatientsRequestFailure, getAllPatientsRequestSuccess, getPatientRequest, getPatientRequestFailure, getPatientRequestSuccess } from "./patient.actions";
import { of } from "rxjs";

@Injectable()
export class PatientsEffects {
    constructor(private actions$: Actions, private patientService: PatientService) {}

    getAllPatients$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getAllPatientsRequest),
            mergeMap(() =>
                this.patientService.getAllPatients().pipe(
                    map((patients) => getAllPatientsRequestSuccess({ patients })),
                    catchError((error) => of(getAllPatientsRequestFailure({ error: error }))),
                ),
            ),
        ),
    );

    getPatient$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getPatientRequest),
            mergeMap((action) =>
                this.patientService.getPatientById(action.patientId).pipe(
                    map((patient) => getPatientRequestSuccess({ patient })),
                    catchError((error) => of(getPatientRequestFailure({ error: error }))),
                ),
            ),
        )
    );
}