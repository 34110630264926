import { Injectable, OnDestroy } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from "rxjs/operators";
import { of } from "rxjs/internal/observable/of";
import { ChatService } from "../../api/chat/chat.service";
import { loadAllMessages, loadAllMessagesFailure, loadAllMessagesSuccess } from "./chat.actions";


@Injectable()
export class ChatEffetcts implements OnDestroy {
    


    constructor(private actions$: Actions, private chatService: ChatService) {}


    loadMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAllMessages),
      mergeMap((action) =>
        this.chatService.getAllMessages(action.room).pipe(
          map((messages) => loadAllMessagesSuccess({ messages })),
          catchError((error) => of(loadAllMessagesFailure({ error: error.message }))),
        ),
      ),
    ),
  );

  ngOnDestroy(): void {
    
  }
}