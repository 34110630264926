import { createAction, props } from '@ngrx/store';
import { Room } from '../../api/models/room';

const LOAD_ROOMS_REQUEST = '[Rooms] Load Rooms';
const LOAD_ROOMS_SUCCESS = '[Rooms] Load Rooms Success';
const LOAD_ROOMS_FAILURE = '[Rooms] Load Rooms Failure';

const ON_FAVORITE_CLICKED = '[Room] Favorite Clicked'
const FIRESTORE_DATA_SET = '[Firestore] Data Set'


// TODO call this from component where you get userId like in src/app/pages/patient-chat/chat/chat.component.ts
export const loadAllRooms = createAction(LOAD_ROOMS_REQUEST, props<{ userId: string }>());

export const loadAllRoomsSuccess = createAction(
  LOAD_ROOMS_SUCCESS,
  props<{ rooms: Room[] }>(),
);

export const loadAllRoomsFailure = createAction(
  LOAD_ROOMS_FAILURE,
  props<{ error: string }>(),
);

export const favoriteClicked = createAction(ON_FAVORITE_CLICKED, props<{ roomID: string, isPriority: boolean }>())

export const firestoreDataSet = createAction(FIRESTORE_DATA_SET)
