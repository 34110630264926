import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { getAllConditionsRequest, getAllConditionsRequestFailure, getAllConditionsRequestSuccess, getConditionRequest, getConditionRequestFailure, getConditionRequestSuccess } from "./condition.actions";
import { catchError, map, mergeMap } from "rxjs/operators";
import { of } from "rxjs/internal/observable/of";
import { BHIService } from "../../api/bhi/bhi.service";


@Injectable()
export class GetAllConditionsEffects {
  constructor (private actions$: Actions, private bhiService: BHIService) { }

  getConditions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getConditionRequest),
      mergeMap((action) =>
        this.bhiService.getConditionById(action.bhiId).pipe(
          map((condition) => getConditionRequestSuccess({ condition })),
          catchError((error) =>
            of(getConditionRequestFailure({ error: error.message }))
          )
        )
      )
    )
  );

  getAllBhiConditions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAllConditionsRequest),
      mergeMap((action) =>
        this.bhiService
          .getAllBHIConditions(action.patientId)
          .pipe(
            map((conditions) => getAllConditionsRequestSuccess({ conditions })),
            catchError((error) =>
              of(getAllConditionsRequestFailure({ error: error.message }))
            )
          )
      )
    )
  );
}