import { createReducer, on } from "@ngrx/store";
import { loadBHINotes, loadBHINotesFailure, loadBHINotesSuccess, saveBHINoteFailure, saveBHINoteRequest, saveBHINoteSuccess } from "./bhi-notes.actions";
import { initialState } from "../auth/auth.reducer";


export const bhiSaveNotesReducer = createReducer(
    initialState,
    on(saveBHINoteRequest, (state) => ({
        ...state,
        loading: true,
        error: null,
        data: null,
    })),
    on(saveBHINoteSuccess, (state, { message }) => ({
        ...state,
        loading: false,
        error: null,
        data: message,
    })),
    on(saveBHINoteFailure, (state, { error }) => ({
        ...state,
        loading: false,
        error: error,
        data: null,
    })),
);

export const bhiGetNotesReducer = createReducer(
    initialState,
    on(loadBHINotes, (state) => ({
        ...state,
        loading: true,
        error: null,
        data: null,
    })),
    on(loadBHINotesSuccess, (state, { notes }) => ({
        ...state,
        loading: false,
        error: null,
        data: notes,
    })),
    on(loadBHINotesFailure, (state, { error }) => ({
        ...state,
        loading: false,
        error: error,
        data: null,
    })),
);