import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthService } from './@core/api/authentication/auth.service';

const routes: Routes = [
  { path: 'ui', redirectTo: 'ui/care-hub', pathMatch: 'full' },
  {
    path: 'ui',
    loadChildren: () => import('./ui/routing/routing.module')
      .then(m => m.RoutingModule),
    canActivate: [AuthService]
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module')
      .then(m => m.AuthModule),
  },
  {
    path: 'all-assessment-questions',
    loadChildren: () => import('./ui/pages/assessment/assessment-questionnaire.module').then(m => m.AssessmentQuestinnaireModule),
  },
  {
    path: 'care-plan-builder',
    loadChildren: () => import('./ui/pages/care-plan-builder/care-plan-builder.module').then(m => m.CarePlanBuilderModule),
  },
  { path: '', redirectTo: 'ui/care-hub', pathMatch: 'full' },
  { path: '**', redirectTo: 'ui/care-hub' },
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
