import { createAction, props } from '@ngrx/store';
import { CallLog } from '../../api/models/data-models';

const SET_CALL_ATTEMPT_REQUEST = '[CallLog] Set Call Attempt Request';
const SET_CALL_ATTEMPT_REQUEST_SUCCESS = '[CallLog] Set Call Attempt Request Success';
const SET_CALL_ATTEMPT_REQUEST_FAILURE = '[CallLog] Set Call Attempt Request Failure';
const GET_CALL_ATTEMPT_REQUEST = '[CallLog] Get Call Attempt Request';
const GET_CALL_ATTEMPT_REQUEST_SUCCESS = '[CallLog] Get Call Attempt Request Success';
const GET_CALL_ATTEMPT_REQUEST_FAILURE = '[CallLog] Get Call Attempt Request Failure';

export const setCallAttemptRequest = createAction(
    SET_CALL_ATTEMPT_REQUEST,
    props<{isCallAttempted: boolean, followUpDate: Date}>(),
);

export const setCallAttemptRequestSuccess = createAction(
    SET_CALL_ATTEMPT_REQUEST_SUCCESS,
    props<{message: string}>(),
);

export const setCallAttemptRequestFailure = createAction(
    SET_CALL_ATTEMPT_REQUEST_FAILURE,
    props<{error: string}>(),
);

export const getCallAttemptRequest = createAction(
    GET_CALL_ATTEMPT_REQUEST,
    props<{clinicId: string, patientId: string}>(),
);

export const getCallAttemptRequestSuccess = createAction(
    GET_CALL_ATTEMPT_REQUEST_SUCCESS,
    props<{callLog: CallLog[]}>(),
);

export const getCallAttemptRequestFailure = createAction(
    GET_CALL_ATTEMPT_REQUEST_FAILURE,
    props<{error: string}>(),
);