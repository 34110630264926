import { createAction, props } from "@ngrx/store";
import { ClinicPatient } from "../../api/models/clinic-patient";

const GET_ALL_PATIENTS_REQUEST = '[Patient] Get All Patients Request';
const GET_ALL_PATIENTS_REQUEST_SUCCESS = '[Patient] Get All Patients Request Success';
const GET_ALL_PATIENTS_REQUEST_FAILURE = '[Patient] Get All Patients Request Failure';

export const getAllPatientsRequest = createAction(GET_ALL_PATIENTS_REQUEST);
export const getAllPatientsRequestSuccess = createAction(GET_ALL_PATIENTS_REQUEST_SUCCESS, props<{ patients: ClinicPatient[] }>());
export const getAllPatientsRequestFailure = createAction(GET_ALL_PATIENTS_REQUEST_FAILURE, props<{ error: string }>());


const GET_PATIENT_REQUEST = '[Patient] Get Patient Request';
const GET_PATIENT_REQUEST_SUCCESS = '[Patient] Get Patient Request Success';
const GET_PATIENT_REQUEST_FAILURE = '[Patient] Get Patient Request Failure';

export const getPatientRequest = createAction(GET_PATIENT_REQUEST, props<{ patientId: string }>());
export const getPatientRequestSuccess = createAction(GET_PATIENT_REQUEST_SUCCESS, props<{ patient: ClinicPatient }>());
export const getPatientRequestFailure = createAction(GET_PATIENT_REQUEST_FAILURE, props<{ error: string }>());

