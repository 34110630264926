import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { getICDCodeRequest, getICDCodeRequestFailure, getICDCodeRequestSuccess } from './icd-codes.actions';
import { catchError, map, mergeMap } from "rxjs/operators";
import { of } from "rxjs/internal/observable/of";
import { BHIService } from "../../api/bhi/bhi.service";

@Injectable()
export class GetAllICD10CodesEffects {

    constructor(private actions$: Actions, private bhiService: BHIService) {}
  
    getICD10Codes$ = createEffect(() =>
      this.actions$.pipe(
        ofType(getICDCodeRequest),
        mergeMap(() =>
          this.bhiService.getICDCodes().pipe(
            map((icd10) => getICDCodeRequestSuccess({ icd10 })),
            catchError((error) => of(getICDCodeRequestFailure({ error: error.message }))),
          ),
        ),
      ),
    );    
  }
  