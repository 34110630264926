import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { getAllClinicUsers, getAllClinicUsersSuccess, getAllClinicUsersFailure,
  getCareTeam, getCareTeamSuccess, getCareTeamFailure,
  addCareTeam, addCareTeamSuccess, addCareTeamFailure,
  removeCareTeam, removeCareTeamSuccess, removeCareTeamFailure } from './care-team.actions';
import { ChatService } from '../../api/chat/chat.service';

@Injectable()
export class CareTeamEffects {

  constructor(private actions$: Actions, private chatService: ChatService) {}

  allClinicUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAllClinicUsers),
      mergeMap((action) =>
        this.chatService.getAllClinicUsers(action.clinicId).pipe(
          map((data) => getAllClinicUsersSuccess({ AllClinicUsers: data})),
          catchError((error) => of(getAllClinicUsersFailure({ error: error }))),
        ),
      ),
    ),
  );

  getCareTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCareTeam),
      mergeMap((action) =>
        this.chatService.readRoom(action.roomId)
        .pipe(
          map((data) => getCareTeamSuccess({ room: data[0]})),
          catchError((error) => of(getCareTeamFailure({ error: error }))),
        ),
      ),
    ),
  );

  addCareTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addCareTeam),
      mergeMap((action) =>
        this.chatService.addCareTeamMember(action.room, action.data).pipe(
          map((data) => addCareTeamSuccess()),
          catchError((error) => of(addCareTeamFailure({ error: error }))),
        ),
      ),
    ),
  );

  removeCareTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeCareTeam),
      mergeMap((action) =>
        this.chatService.removeCareTeamMember(action.room, action.data).pipe(
          map((data) => removeCareTeamSuccess()),
          catchError((error) => of(removeCareTeamFailure({ error: error }))),
        ),
      ),
    ),
  );
}
