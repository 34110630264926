export enum THEME_ICONS {
  "ALERT_CIRCLE" = `../../assets/icons/alert-circle.svg`,
  "ARROW_DOWN_ICON" = `../../assets/icons/arrow-down-icon.svg`,
  "ARROW_UP_ICON" = `../../assets/icons/arrow-up-icon.svg`,
  "ARROW_UP" = `../../assets/icons/arrow-up.svg`,
  "ATTACHMENT_OUTLINE" = `../../assets/icons/attachment-outline.svg`,
  "BILLING_FILLED" = `../../assets/icons/billing-filled.svg`,
  "BILLING_OUTLINED" = `../../assets/icons/billing-outline.svg`,
  "BLUE_BACK_ARROW" = `../../assets/icons/blue-back-arrow.svg`,
  "BRAIN_FILLED" = `../../assets/icons/brain-filled.svg`,
  "BRAIN_OUTLINED" = `../../assets/icons/brain-outline.svg`,
  "CALL_FAILED_OUTLINED" = `../../assets/icons/call-fail-outline.svg`,
  "CALL_ICON" = `../../assets/icons/call-icon.svg`,
  "CALL_OUTLINED" = `../../assets/icons/call-outline.svg`,
  "CALL_SUCCESS_OUTLINED" = `../../assets/icons/call-success-outline.svg`,
  "CHAT_OUTLINED" = `../../assets/icons/chat-outline.svg`,
  "CHECK_CIRCLE" = `../../assets/icons/check-circle.svg`,
  "CHEVRON_DOWN" = `../../assets/icons/chevron-down.svg`,
  "CLIPBOARD_OUTLINED" = `../../assets/icons/clipboard-outline.svg`,
  "CLOSE_ICON" = `../../assets/icons/close-icon.svg`,
  "COLLAPSE_OUTLINED" = `../../assets/icons/collapse-outline.svg`,
  "DATA_LIBRARY_FILLED" = `../../assets/icons/data-library-filled.svg`,
  "DATA_LIBRARY_OUTLINED" = `../../assets/icons/data-library-outline.svg`,
  "DATE_PICKER" = `../../assets/icons/date-picker.svg`,
  "DELETE_ICON_RED" = `../../assets/icons/delete-icon-red.svg`,
  "DOWN_ARROW" = `../../assets/icons/down-arrow.svg`,
  "DOWNLOAD_ICON" = `../../assets/icons/download-icon.svg`,
  "EDIT_ICON_BLUE" = `../../assets/icons/edit-icon-blue.svg`,
  "FILE_FILLED" = `../../assets/icons/file-filled.svg`,
  "FILE_OUTLINED" = `../../assets/icons/file-outline.svg`,
  "FILTER_LINE_ICON" = `../../assets/icons/filter-lines.svg`,
  "FILTER_OUTLINED" = `../../assets/icons/filter-outline.svg`,
  "HEART_FILLED" = `../../assets/icons/heart-filled.svg`,
  "HEART_OUTLINED" = `../../assets/icons/heart-outline.svg`,
  "IMAGE_OUTLINED" = `../../assets/icons/image-outline.svg`,
  "LEFT_BACK_ARROW" = `../../assets/icons/left-back-arrow.svg`,
  "LIBRARY_FILLED" = `../../assets/icons/library-filled.svg`,
  "LIBRARY_OUTLINED" = `../../assets/icons/library-outline.svg`,
  "LOCK_FILLED" = `../../assets/icons/lock-filled.svg`,
  "LOCK_OUTLINED" = `../../assets/icons/lock-outline.svg`,
  "MENU_OUTLINED" = `../../assets/icons/menu-outline.svg`,
  "NEW_CHAT_BLACK" = `../../assets/icons/new-chat-black.svg`,
  "NEW_CHAT_WHITE" = `../../assets/icons/new-chat-white.svg`,
  "PAUSE_TIMER_WHITE" = `../../assets/icons/pause-timer-white.svg`,
  "PLAY_TIMER_WHITE" = `../../assets/icons/play-timer-white.svg`,
  "PLUS_ICON" = `../../assets/icons/plus-icon.svg`,
  "POPULATION_FILLED" = `../../assets/icons/population-filled.svg`,
  "POPULATION_OUTLINED" = `../../assets/icons/population-outline.svg`,
  "PRINT_ICON" = `../../assets/icons/print-icon.svg`,
  "REPORT_BUTTON" = `../../assets/icons/report-button.svg`,
  "RESET_ICON" = `../../assets/icons/reset-icon.svg`,
  "RIGHT_ARROW" = `../../assets/icons/right-arrow.svg`,
  "RIGHT_TICK_GREEN" = `../../assets/icons/right-tick-green.svg`,
  "SEARCH_ICON" = `../../assets/icons/search-icon.svg`,
  "SETTINGS_OUTLINED" = `../../assets/icons/settings-outline.svg`,
  "SORT_ARROW" = `../../assets/icons/sort-arrow.svg`,
  "STAR_FILLED_BIG" = `../../assets/icons/star-filled-big.svg`,
  "STAR_FILLED" = `../../assets/icons/star-filled.svg`,
  "STAR_OUTLINED" = `../../assets/icons/star-outline.svg`,
  "STOP_TIMER_WHITE" = `../../assets/icons/stop-timer-white.svg`,
  "TALK_FILLED" = `../../assets/icons/talk-filled.svg`,
  "THREE_DOTS" = `../../assets/icons/three-dots.svg`,
  "UH_LOGO" = `../../assets/icons/uh-logo.svg`,
  "UNDO_ICON" = `../../assets/icons/undo.svg`,
  "USER_CIRCLE" = `../../assets/icons/user-circle.svg`,
  "USER_CIRCLE_BLUE" = `../../assets/icons/user-circle-blue.svg`,

  "CLOSE_CHAT" = `../../assets/icons/close-chat.svg`,
  "PLUS_ICON_WHITE" = `../../assets/icons/plus-icon-white.svg`,
  "AT_THE_RATE" = `../../assets/icons/at-the-rate.svg`,
  "AT_THE_RATE_BLUE" = `../../assets/icons/at-the-rate-blue.svg`,
  "SEND_ICON" = `../../assets/icons/send-icon.svg`,
  "PDF_ICON" = `../../assets/icons/pdf.svg`,
  "COUNCIL_ICON" = `../../assets/icons/council.svg`,
  "AGENT_ICON" = `../../assets/icons/agent.svg`,
}

export enum ICON_SIZE {
  SMALL = "small",
  REGULAR = "regular",
  LARGE = "large",
  HUGE = "huge",
  TINY = "tiny",
  EXTRA_HUGE = "extraHuge",
  EXTRA_TINY = "extraTiny",
  CUSTOM_SIZE = "custom",
}
