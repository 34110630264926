import { createAction, props } from "@ngrx/store";
import { UserDetails } from "../../api/models/data-models";
import { LoginRequest } from "./login_request";

const LOGIN_USER_REQUEST = "[User] Load User";
const LOGIN_USER_SUCCESS = "[User] Load User Success";
const LOGIN_USER_FAILURE = "[User] Load User Failure";
const LOGOUT_USER_REQUEST = "[User] Logout User";
const LOGOUT_USER_FAILURE = "[User] Logout User Failure";
const LOGOUT_USER_SUCCESS = "[User] Logout User Success";
const RESET_PASSWORD_REQUEST = "[User] Reset Password Request";
const RESET_PASSWORD_SUCCESS = "[User] Reset Password Success";
const RESET_PASSWORD_FAILURE = "[User] Reset Password Failure";

export const loginUser = createAction(
  LOGIN_USER_REQUEST,
  props<{ loginRequest: LoginRequest }>()
);

export const loginUserSuccess = createAction(
  LOGIN_USER_SUCCESS,
  props<{ user: UserDetails }>()
);

export const loginUserFailure = createAction(
  LOGIN_USER_FAILURE,
  props<{ error: string }>()
);

export const logoutUser = createAction(LOGOUT_USER_REQUEST);

export const logoutUserSuccess = createAction(
  LOGOUT_USER_SUCCESS,
  props<{ message: string }>()
);

export const logoutUserFailure = createAction(
  LOGOUT_USER_FAILURE,
  props<{ error: string }>()
);

export const resetPassword = createAction(
  RESET_PASSWORD_REQUEST,
  props<{ email: string }>()
);

export const resetPasswordSuccess = createAction(
  RESET_PASSWORD_SUCCESS,
  props<{ message: string }>()
);

export const resetPasswordFailure = createAction(
  RESET_PASSWORD_FAILURE,
  props<{ error: string }>()
);
