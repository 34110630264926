import { Component, Input } from '@angular/core';

enum font_weight {
  REGULAR = '400',
  SEMIBOLD = '700',
  BOLD = '700',
  EXTRABOLD = '900'
}

@Component({
  selector: 'h1-regular',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class H1Regular {
  @Input() color: string;
  fontSize: string = "6rem";
  linHeight: string = "6rem";
  fontWeight: string = font_weight.REGULAR
}

@Component({
  selector: 'h1-bold',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class H1Bold {
  @Input() color: string;
  fontSize: string = "6rem";
  linHeight: string = "6rem";
  fontWeight: string = font_weight.BOLD
}

@Component({
  selector: 'h1-semiBold',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class H1SemiBold {
  @Input() color: string;
  fontSize: string = "6rem";
  linHeight: string = "6rem";
  fontWeight: string = font_weight.SEMIBOLD
}

@Component({
  selector: 'h1-extraBold',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class H1ExtraBold {
  @Input() color: string;
  fontSize: string = "6rem";
  linHeight: string = "6rem";
  fontWeight: string = font_weight.EXTRABOLD
}

@Component({
  selector: 'h2-regular',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class H2Regular {
  @Input() color: string;
  fontSize: string = "5rem";
  linHeight: string = "5rem";
  fontWeight: string = font_weight.REGULAR
}

@Component({
  selector: 'h2-bold',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class H2Bold {
  @Input() color: string;
  fontSize: string = "5rem";
  linHeight: string = "5rem";
  fontWeight: string = font_weight.BOLD
}

@Component({
  selector: 'h2-semiBold',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class H2SemiBold {
  @Input() color: string;
  fontSize: string = "5rem";
  linHeight: string = "5rem";
  fontWeight: string = font_weight.SEMIBOLD
}

@Component({
  selector: 'h2-extraBold',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class H2ExtraBold {
  @Input() color: string;
  fontSize: string = "5rem";
  linHeight: string = "5rem";
  fontWeight: string = font_weight.EXTRABOLD
}


@Component({
  selector: 'h3-regular',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class H3Regular {
  @Input() color: string;
  fontSize: string = "4rem";
  linHeight: string = "4rem";
  fontWeight: string = font_weight.REGULAR
}

@Component({
  selector: 'h3-bold',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class H3Bold {
  @Input() color: string;
  fontSize: string = "4rem";
  linHeight: string = "4rem";
  fontWeight: string = font_weight.BOLD
}

@Component({
  selector: 'h3-semiBold',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class H3SemiBold {
  @Input() color: string;
  fontSize: string = "4rem";
  linHeight: string = "4rem";
  fontWeight: string = font_weight.SEMIBOLD
}

@Component({
  selector: 'h3-extraBold',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class H3ExtraBold {
  @Input() color: string;
  fontSize: string = "4rem";
  linHeight: string = "4rem";
  fontWeight: string = font_weight.EXTRABOLD
}

@Component({
  selector: 'h4-regular',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class H4Regular {
  @Input() color: string;
  fontSize: string = "3.25rem";
  linHeight: string = "3.25rem";
  fontWeight: string = font_weight.REGULAR
}

@Component({
  selector: 'h4-bold',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class H4Bold {
  @Input() color: string;
  fontSize: string = "3.25rem";
  linHeight: string = "3.25rem";
  fontWeight: string = font_weight.BOLD
}

@Component({
  selector: 'h4-semiBold',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class H4SemiBold {
  @Input() color: string;
  fontSize: string = "3.25rem";
  linHeight: string = "3.25rem";
  fontWeight: string = font_weight.SEMIBOLD
}

@Component({
  selector: 'h4-extraBold',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class H4ExtraBold {
  @Input() color: string;
  fontSize: string = "3.25rem";
  linHeight: string = "3.25rem";
  fontWeight: string = font_weight.EXTRABOLD
}

@Component({
  selector: 'h5-regular',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class H5Regular {
  @Input() color: string;
  fontSize: string = "2.625rem";
  linHeight: string = "3rem";
  fontWeight: string = font_weight.REGULAR
}

@Component({
  selector: 'h5-bold',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class H5Bold {
  @Input() color: string;
  fontSize: string = "2.625rem";
  linHeight: string = "3rem";
  fontWeight: string = font_weight.BOLD
}

@Component({
  selector: 'h5-semiBold',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class H5SemiBold {
  @Input() color: string;
  fontSize: string = "2.625rem";
  linHeight: string = "3rem";
  fontWeight: string = font_weight.SEMIBOLD
}

@Component({
  selector: 'h5-extraBold',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class H5ExtraBold {
  @Input() color: string;
  fontSize: string = "2.625rem";
  linHeight: string = "3rem";
  fontWeight: string = font_weight.EXTRABOLD
}

@Component({
  selector: 'h6-regular',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class H6Regular {
  @Input() color: string;
  fontSize: string = "2rem";
  linHeight: string = "2.375rem";
  fontWeight: string = font_weight.REGULAR
}

@Component({
  selector: 'h6-bold',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class H6Bold {
  @Input() color: string;
  fontSize: string = "2rem";
  linHeight: string = "2.375rem";
  fontWeight: string = font_weight.BOLD
}

@Component({
  selector: 'h6-semiBold',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class H6SemiBold {
  @Input() color: string;
  fontSize: string = "2rem";
  linHeight: string = "2.375rem";
  fontWeight: string = font_weight.SEMIBOLD
}

@Component({
  selector: 'h6-extraBold',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class H6ExtraBold {
  @Input() color: string;
  fontSize: string = "2rem";
  linHeight: string = "2.375rem";
  fontWeight: string = font_weight.EXTRABOLD
}

@Component({
  selector: 'h7-regular',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class H7Regular {
  @Input() color: string;
  fontSize: string = "1.75rem";
  linHeight: string = "2.125rem";
  fontWeight: string = font_weight.REGULAR
}

@Component({
  selector: 'h7-bold',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class H7Bold {
  @Input() color: string;
  fontSize: string = "1.75rem";
  linHeight: string = "2.125rem";
  fontWeight: string = font_weight.BOLD
}

@Component({
  selector: 'h7-semiBold',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class H7SemiBold {
  @Input() color: string;
  fontSize: string = "1.75rem";
  linHeight: string = "2.125rem";
  fontWeight: string = font_weight.SEMIBOLD
}

@Component({
  selector: 'h7-extraBold',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class H7ExtraBold {
  @Input() color: string;
  fontSize: string = "1.75rem";
  linHeight: string = "2.125rem";
  fontWeight: string = font_weight.EXTRABOLD
}

@Component({
  selector: 'h8-regular',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class H8Regular {
  @Input() color: string;
  fontSize: string = "1.3125rem";
  linHeight: string = "1.875rem";
  fontWeight: string = font_weight.REGULAR
}

@Component({
  selector: 'h8-bold',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class H8Bold {
  @Input() color: string;
  fontSize: string = "1.3125rem";
  linHeight: string = "1.875rem";
  fontWeight: string = font_weight.BOLD
}

@Component({
  selector: 'h8-semiBold',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class H8SemiBold {
  @Input() color: string;
  fontSize: string = "1.3125rem";
  linHeight: string = "1.875rem";
  fontWeight: string = font_weight.SEMIBOLD
}

@Component({
  selector: 'h8-extraBold',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class H8ExtraBold {
  @Input() color: string;
  fontSize: string = "1.3125rem";
  linHeight: string = "1.875rem";
  fontWeight: string = font_weight.EXTRABOLD
}

@Component({
  selector: 'h9-regular',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class H9Regular {
  @Input() color: string;
  fontSize: string = "1.3125rem";
  linHeight: string = "1.5rem";
  fontWeight: string = font_weight.REGULAR
}

@Component({
  selector: 'h9-bold',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class H9Bold {
  @Input() color: string;
  fontSize: string = "1.3125rem";
  linHeight: string = "1.5rem";
  fontWeight: string = font_weight.BOLD
}

@Component({
  selector: 'h9-semiBold',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class H9SemiBold {
  @Input() color: string;
  fontSize: string = "1.3125rem";
  linHeight: string = "1.5rem";
  fontWeight: string = font_weight.SEMIBOLD
}

@Component({
  selector: 'h9-extraBold',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class H9ExtraBold {
  @Input() color: string;
  fontSize: string = "1.3125rem";
  linHeight: string = "1.5rem";
  fontWeight: string = font_weight.EXTRABOLD
}

@Component({
  selector: 'h10-regular',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class H10Regular {
  @Input() color: string;
  fontSize: string = "1.125rem";
  linHeight: string = "1.375rem";
  fontWeight: string = font_weight.REGULAR
}

@Component({
  selector: 'h10-bold',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class H10Bold {
  @Input() color: string;
  fontSize: string = "1.125rem";
  linHeight: string = "1.375rem";
  fontWeight: string = font_weight.BOLD
}

@Component({
  selector: 'h10-semiBold',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class H10SemiBold {
  @Input() color: string;
  fontSize: string = "1.125rem";
  linHeight: string = "1.375rem";
  fontWeight: string = font_weight.SEMIBOLD
}

@Component({
  selector: 'h10-extraBold',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class H10ExtraBold {
  @Input() color: string;
  fontSize: string = "1.125rem";
  linHeight: string = "1.375rem";
  fontWeight: string = font_weight.EXTRABOLD
}

@Component({
  selector: 'h11-regular',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class H11Regular {
  @Input() color: string;
  fontSize: string = "1rem";
  linHeight: string = "1.25rem";
  fontWeight: string = font_weight.REGULAR
}

@Component({
  selector: 'h11-bold',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class H11Bold {
  @Input() color: string;
  fontSize: string = "1rem";
  linHeight: string = "1.25rem";
  fontWeight: string = font_weight.BOLD
}

@Component({
  selector: 'h11-semiBold',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class H11SemiBold {
  @Input() color: string;
  fontSize: string = "1rem";
  linHeight: string = "1.25rem";
  fontWeight: string = font_weight.SEMIBOLD
}

@Component({
  selector: 'h11-extraBold',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class H11ExtraBold {
  @Input() color: string;
  fontSize: string = "1rem";
  linHeight: string = "1.25rem";
  fontWeight: string = font_weight.EXTRABOLD
}


@Component({
  selector: 'h12-regular',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class H12Regular {
  @Input() color: string;
  fontSize: string = "0.875rem";
  linHeight: string = "1.125rem";
  fontWeight: string = font_weight.REGULAR
}

@Component({
  selector: 'h12-bold',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class H12Bold {
  @Input() color: string;
  fontSize: string = "0.875rem";
  linHeight: string = "1.125rem";
  fontWeight: string = font_weight.BOLD
}

@Component({
  selector: 'h12-semiBold',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class H12SemiBold {
  @Input() color: string;
  fontSize: string = "0.875rem";
  linHeight: string = "1.125rem";
  fontWeight: string = font_weight.SEMIBOLD
}

@Component({
  selector: 'h12-extraBold',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class H12ExtraBold {
  @Input() color: string;
  fontSize: string = "0.875rem";
  linHeight: string = "1.125rem";
  fontWeight: string = font_weight.EXTRABOLD
}


@Component({
  selector: 'tiny-regular',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class TinyRegular {
  @Input() color: string;
  fontSize: string = "0.75rem";
  linHeight: string = "1.125rem";
  fontWeight: string = font_weight.REGULAR

}

@Component({
  selector: 'tiny-bold',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class TinyBold {
  @Input() color: string;
  fontSize: string = "0.75rem";
  linHeight: string = "1.125rem";
  fontWeight: string = font_weight.BOLD
}

@Component({
  selector: 'tiny-semiBold',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class TinySemiBold {
  @Input() color: string;
  fontSize: string = "0.75rem";
  linHeight: string = "1.125rem";
  fontWeight: string = font_weight.SEMIBOLD
}

@Component({
  selector: 'tiny-extraBold',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class TinyExtraBold {
  @Input() color: string;
  fontSize: string = "0.75rem";
  linHeight: string = "1.125rem";
  fontWeight: string = font_weight.EXTRABOLD
}


@Component({
  selector: 'xlarge-regular',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class XLargeRegular {
  @Input() color: string;
  fontSize: string = "1.3125rem";
  linHeight: string = "1.875rem";
  fontWeight: string = font_weight.REGULAR
}

@Component({
  selector: 'xlarge-bold',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class XLargeBold {
  @Input() color: string;
  fontSize: string = "1.3125rem";
  linHeight: string = "1.875rem";
  fontWeight: string = font_weight.BOLD
}

@Component({
  selector: 'xlarge-extraBold',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class XLargeExtraBold {
  @Input() color: string;
  fontSize: string = "1.3125rem";
  linHeight: string = "1.875rem";
  fontWeight: string = font_weight.EXTRABOLD
}

@Component({
  selector: 'xlarge-semiBold',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class XLargeSemiBold {
  @Input() color: string;
  fontSize: string = "1.3125rem";
  linHeight: string = "1.875rem";
  fontWeight: string = font_weight.SEMIBOLD
}

@Component({
  selector: 'large-regular',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class LargeRegular {
  @Input() color: string;
  fontSize: string = "1.125rem";
  linHeight: string = "1.625rem";
  fontWeight: string = font_weight.REGULAR
}

@Component({
  selector: 'large-bold',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class LargeBold {
  @Input() color: string;
  fontSize: string = "1.125rem";
  linHeight: string = "1.625rem";
  fontWeight: string = font_weight.BOLD
}

@Component({
  selector: 'large-extraBold',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class LargeExtraBold {
  @Input() color: string;
  fontSize: string = "1.125rem";
  linHeight: string = "1.625rem";
  fontWeight: string = font_weight.EXTRABOLD
}

@Component({
  selector: 'large-semiBold',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class LargeSemiBold {
  @Input() color: string;
  fontSize: string = "1.125rem";
  linHeight: string = "1.625rem";
  fontWeight: string = font_weight.SEMIBOLD
}

@Component({
  selector: 'small-regular',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class SmallRegular {
  @Input() color: string;
  fontSize: string = "0.875rem";
  linHeight: string = "1.375rem";
  fontWeight: string = font_weight.REGULAR
}

@Component({
  selector: 'small-bold',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class SmallBold {
  @Input() color: string;
  fontSize: string = "0.875rem";
  linHeight: string = "1.375rem";
  fontWeight: string = font_weight.BOLD
}

@Component({
  selector: 'small-extraBold',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class SmallExtraBold {
  @Input() color: string;
  fontSize: string = "0.875rem";
  linHeight: string = "1.375rem";
  fontWeight: string = font_weight.EXTRABOLD
}

@Component({
  selector: 'small-semiBold',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class SmallSemiBold {
  @Input() color: string;
  fontSize: string = "0.875rem";
  linHeight: string = "1.375rem";
  fontWeight: string = font_weight.SEMIBOLD
}

@Component({
  selector: 'body-regular',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class BodyRegular {
  @Input() color: string;
  fontSize: string = "1rem";
  linHeight: string = "1.5rem";
  fontWeight: string = font_weight.REGULAR
}

@Component({
  selector: 'body-bold',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class BodyBold {
  @Input() color: string;
  fontSize: string = "1rem";
  linHeight: string = "1.5rem";
  fontWeight: string = font_weight.BOLD
}

@Component({
  selector: 'body-extraBold',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class BodyExtraBold {
  @Input() color: string;
  fontSize: string = "1rem";
  linHeight: string = "1.5rem";
  fontWeight: string = font_weight.EXTRABOLD
}

@Component({
  selector: 'body-semiBold',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class BodySemiBold {
  @Input() color: string;
  fontSize: string = "1rem";
  linHeight: string = "1.5rem";
  fontWeight: string = font_weight.SEMIBOLD
}

@Component({
  selector: 'extraTiny-regular',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class ExtraTinyRegular {
  @Input() color: string;
  fontSize: string = "0.6875rem";
  linHeight: string = "1.125rem";
  fontWeight: string = font_weight.REGULAR
}

@Component({
  selector: 'extraTiny-bold',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class ExtraTinyBold {
  @Input() color: string;
  fontSize: string = "0.6875rem";
  linHeight: string = "1.125rem";
  fontWeight: string = font_weight.BOLD
}

@Component({
  selector: 'extraTiny-extraBold',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class ExtraTinyExtraBold {
  @Input() color: string;
  fontSize: string = "0.6875rem";
  linHeight: string = "1.125rem";
  fontWeight: string = font_weight.EXTRABOLD
}

@Component({
  selector: 'extraTiny-semiBold',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class ExtraTinySemiBold {
  @Input() color: string;
  fontSize: string = "0.6875rem";
  linHeight: string = "1.125rem";
  fontWeight: string = font_weight.SEMIBOLD
}

@Component({
  selector: 'overline-regular',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class OverlineRegular {
  @Input() color: string;
  fontSize: string = "0.625rem";
  linHeight: string = "1rem";
  fontWeight: string = font_weight.REGULAR
}

@Component({
  selector: 'overline-bold',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class OverlineBold {
  @Input() color: string;
  fontSize: string = "0.625rem";
  linHeight: string = "1rem";
  fontWeight: string = font_weight.BOLD
}

@Component({
  selector: 'overline-extraBold',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class OverlineExtraBold {
  @Input() color: string;
  fontSize: string = "0.625rem";
  linHeight: string = "1rem";
  fontWeight: string = font_weight.EXTRABOLD
}

@Component({
  selector: 'overline-semiBold',
  templateUrl: './custom-typography.component.html',
  styleUrls: ['./custom-typography.component.scss']
})
export class OverlineSemiBold {
  @Input() color: string;
  fontSize: string = "0.625rem";
  linHeight: string = "1rem";
  fontWeight: string = font_weight.SEMIBOLD
}

export const TYPOGRAPHY = [

  H1Regular,
  H1Bold, H1SemiBold, H1ExtraBold,
  H2Regular, H2Bold, H2SemiBold, H2ExtraBold,
  H3Regular, H3Bold, H3SemiBold, H3ExtraBold,
  H4Regular, H4Bold, H4SemiBold, H4ExtraBold,
  H5Regular, H5Bold, H5SemiBold, H5ExtraBold,
  H6Regular, H6Bold, H6SemiBold, H6ExtraBold,
  H7Regular, H7Bold, H7SemiBold, H7ExtraBold,
  H8Regular, H8Bold, H8SemiBold, H8ExtraBold,
  H9Regular, H9Bold, H9SemiBold, H9ExtraBold,
  H10Regular, H10Bold, H10SemiBold, H10ExtraBold,
  H11Regular, H11Bold, H11SemiBold, H11ExtraBold,
  H12Regular, H12Bold, H12SemiBold, H12ExtraBold,
  TinyRegular, TinyBold, TinySemiBold, TinyExtraBold,
  XLargeRegular, XLargeBold, XLargeSemiBold, XLargeExtraBold,
  LargeRegular, LargeBold, LargeSemiBold, LargeExtraBold,
  SmallRegular, SmallBold, SmallSemiBold, SmallExtraBold,
  TinyRegular, TinyBold, TinySemiBold, TinyExtraBold,
  BodyRegular, BodyBold, BodySemiBold, BodyExtraBold,
  ExtraTinyRegular, ExtraTinyBold, ExtraTinySemiBold, ExtraTinyExtraBold,
  OverlineRegular, OverlineBold, OverlineSemiBold, OverlineExtraBold,

]