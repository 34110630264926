<div
  [ngClass]="this.isSideBarHidden ? 'logo-container_hide' : 'logo-container'"
>
  <div class="logo-container_icon">
    <nb-icon icon="uh-logo" pack="oasis"></nb-icon>
  </div>
  <div
    class="logo-container_text"
    [ngClass]="
      this.isSideBarHidden ? 'logo-container_text hide' : 'logo-container_text'
    "
  >
    <div>
      <overline-regular>Powered by</overline-regular>
    </div>
    <div>
      <tiny-bold>Urban Health</tiny-bold>
    </div>
  </div>
</div>
