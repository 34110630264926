import { createReducer, on } from '@ngrx/store';
import { getCallAttemptRequest, getCallAttemptRequestFailure, getCallAttemptRequestSuccess, setCallAttemptRequest, setCallAttemptRequestFailure, setCallAttemptRequestSuccess } from './call-attempt.actions';
import { ResponseState } from '../../api/models/response_state';

const initialState: ResponseState = {
    data: null,
    loading: false,
    error: null,
};

//set call
export const setCallAttemptReducer = createReducer(
    initialState,
    on(setCallAttemptRequest, (state) => ({
      ...state,
      loading: true,
      error: null,
      data: null,
    })),
    on(setCallAttemptRequestSuccess, (state, { message }) => ({
      ...state,
      loading: false,
      error: null,
      data: message,
    })),
    on(setCallAttemptRequestFailure, (state, { error }) => ({
      ...state,
      loading: false,
      error: error,
      data: null,
    })),
  );

  // get call
  export const getCallAttemptReducer = createReducer(
    initialState,
    on(getCallAttemptRequest, (state) => ({
      ...state,
      loading: true,
      error: null,
      data: null,
    })),
    on(getCallAttemptRequestSuccess, (state, { callLog }) => ({
      ...state,
      loading: false,
      error: null,
      data: callLog,
    })),
    on(getCallAttemptRequestFailure, (state, { error }) => ({
      ...state,
      loading: false,
      error: error,
      data: null,
    })),
  );