import { Injectable } from "@angular/core";
import { orderBy, where, arrayRemove, arrayUnion } from "@angular/fire/firestore";
import { Observable } from "rxjs";
import { FirestoreService } from "../base/firestore-service";
import { EventEmitter } from "@angular/core";
import { ParticipantDetails, Room, UserNote } from "../models/room";


// TODO: remove usage of this service from components. use NgRX


@Injectable({
    providedIn: 'root'
  })
export class ChatService {

    constructor(private db: FirestoreService) { }

    roomSelected: EventEmitter<Room> = new EventEmitter<Room>();

    setRoom(room: Room) {
      this.roomSelected.emit(room);
    }

    getAllMessages(room: Room): Observable<any[]> {
      return this.db.readCollection(`rooms/${room.id}/messages`, orderBy('createdAt', 'asc'));
    }

    getAllRooms(userId: string): Observable<Room[]>  {
      return this.db.readCollection<Room>('rooms', where('participantList.clinicUsers', "array-contains", userId));
    }

    readRoom(roomId: string): Observable<any> {
      return this.db.readDocument(`rooms`, roomId);
    }
    
    updatePriorityState(roomId: string, isPriority: boolean): Observable<any> {

      if (!isPriority) {
        return this.db.setDocument(`rooms`, roomId, { tags: arrayRemove("priority") }, true)
      } else {
        return this.db.setDocument(`rooms`, roomId, { tags: arrayUnion("priority") }, true)
      }
    }

    getUserNotes(roomId: string) {
      return this.db.readCollection<UserNote>(`rooms/${roomId}/userNotes`, orderBy("createdOn", "asc"))
    }

    setuserNotes(roomId: string, userNote: UserNote): Observable<void> {
      return this.db.setDocument(`rooms/${roomId}/userNotes`, userNote.id, {
        text: userNote.text,
        createdOn: userNote.createdOn
      }, true)
    }

    getAllClinicUsers(clinicId: string) : Observable<ParticipantDetails[]> {
      return this.db.readCollection<ParticipantDetails>(`clinic/${clinicId}/members`, orderBy('name', 'asc'));
    }

    addCareTeamMember(room: Room, member: ParticipantDetails) : Observable<void> {
      let newUserId = member.id;
      let newParticipantDetails = {...room.participantDetailsMap, [newUserId]: member };
      let newClinicUsers = [...room.participantList.clinicUsers, newUserId ];
      let newParticipantList = {...room.participantList, clinicUsers: newClinicUsers};
      return this.db.setDocument(`rooms`, room.id, {participantDetails: newParticipantDetails, participantList: newParticipantList}, true);
    }

    removeCareTeamMember(room: Room, member: ParticipantDetails) : Observable<void> {
      let userId = member.id;
      let newParticipantDetails = {...room.participantDetailsMap};
      delete newParticipantDetails[userId];
      let newClinicUsers = room.participantList.clinicUsers.filter( (id)=>id!==userId );
      let newParticipantList = {...room.participantList, clinicUsers: newClinicUsers};
      return this.db.setDocument(`rooms`, room.id, {
        participantDetails: newParticipantDetails,
        participantList: newParticipantList
      }, true);
    }

}
