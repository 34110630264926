import {createAction, props} from '@ngrx/store';
import { ParticipantDetails, Room } from '../../api/models/room';

const GET_ALL_CLINIC_USERS_REQUEST = '[Clinic User] Get All Clinic Users';
const GET_ALL_CLINIC_USERS_SUCCESS = '[Clinic User] Get All Clinic Users Success';
const GET_ALL_CLINIC_USERS_FAILURE = '[Clinic User] Get All Clinic Users Failure';
const GET_CARE_TEAM_REQUEST = '[Clinic User] Get Care Team';
const GET_CARE_TEAM_SUCCESS = '[Clinic User] Get Care Team Success';
const GET_CARE_TEAM_FAILURE = '[Clinic User] Get Care Team Failure';
const ADD_CARE_TEAM_REQUEST = '[Clinic User] Add Care Team';
const ADD_CARE_TEAM_SUCCESS = '[Clinic User] Add Care Team Success';
const ADD_CARE_TEAM_FAILURE = '[Clinic User] Add Care Team Failure';
const REMOVE_CARE_TEAM_REQUEST = '[Clinic User] Remove Care Team';
const REMOVE_CARE_TEAM_SUCCESS = '[Clinic User] Remove Care Team Success';
const REMOVE_CARE_TEAM_FAILURE = '[Clinic User] Remove Care Team Failure';

export const getAllClinicUsers = createAction(GET_ALL_CLINIC_USERS_REQUEST, props<{ clinicId: string }>());

export const getAllClinicUsersSuccess = createAction(
  GET_ALL_CLINIC_USERS_SUCCESS,
  props<{ AllClinicUsers: ParticipantDetails[] }>(),
);

export const getAllClinicUsersFailure = createAction(
  GET_ALL_CLINIC_USERS_FAILURE,
  props<{ error: string }>(),
);

export const getCareTeam = createAction(GET_CARE_TEAM_REQUEST, props<{ roomId: string }>());

export const getCareTeamSuccess = createAction(
  GET_CARE_TEAM_SUCCESS,
  props<{ room: Room }>(),
);

export const getCareTeamFailure = createAction(
  GET_CARE_TEAM_FAILURE,
  props<{ error: string }>(),
);

export const addCareTeam = createAction(ADD_CARE_TEAM_REQUEST, props<{ room: Room, data: ParticipantDetails }>());

export const addCareTeamSuccess = createAction(
  ADD_CARE_TEAM_SUCCESS,
);

export const addCareTeamFailure = createAction(
  ADD_CARE_TEAM_FAILURE,
  props<{ error: string }>(),
);

export const removeCareTeam = createAction(REMOVE_CARE_TEAM_REQUEST, props<{ room: Room, data: ParticipantDetails }>());

export const removeCareTeamSuccess = createAction(
  REMOVE_CARE_TEAM_SUCCESS,
);

export const removeCareTeamFailure = createAction(
  REMOVE_CARE_TEAM_FAILURE,
  props<{ error: string }>(),
);
