import { Injectable } from "@angular/core";
import { UserDetails } from "../models/data-models";
import { PatientIdentifier } from "../models/clinic-patient";

@Injectable({
  providedIn: "root",
})
export class LocalStorageService {
  readonly USER_DETAILS_LOCAL = "user-details-local";
  readonly ASSESSMENTS_DATA = "ASSESSMENTS_DATA";
  readonly CARE_PLAN_BUILDER_DATA = "CARE_PLAN_BUILDER_DATA";
  readonly PATIENT_IDENTIFIER = "patient-identifier";

  setItem<T>(key: string, value: T): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  getItem<T>(key: string): T | null {
    const storedValue = localStorage.getItem(key);
    if (storedValue) {
      return JSON.parse(storedValue);
    }
    return null;
  }

  removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  getUserId(): string | null {
    const userDetails = this.getItem<UserDetails>(this.USER_DETAILS_LOCAL);
    return userDetails?.userId || null;
  }

  getClinicID(): string | null {
    const userDetails = this.getItem<UserDetails>(this.USER_DETAILS_LOCAL);
    return userDetails?.clinicDetails?.clinicId || null;
  }

  setPatientIdentifierInLocalStorage(
    patientIdentifier: PatientIdentifier[]
  ): void {
    this.setItem(this.PATIENT_IDENTIFIER, patientIdentifier);
  }

  getPatientIdentifierInLocalStorage(): PatientIdentifier[] | null {
    return this.getItem(this.PATIENT_IDENTIFIER);
  }
}
