import { Component, OnInit } from '@angular/core';
import { NbSidebarService, NbMediaBreakpointsService, NbThemeService } from '@nebular/theme';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../../@core/api/base/base-component';

@Component({
  selector: 'ngx-app-logo',
  templateUrl: './app-logo.component.html',
  styleUrls: ['./app-logo.component.scss']
})
export class AppLogoComponent extends BaseComponent implements OnInit {

  isSideBarHidden: boolean = false
  private destroy$: Subject<void> = new Subject<void>();

  constructor (private sideBarService: NbSidebarService, private breakpointService: NbMediaBreakpointsService,
    private themeService: NbThemeService) {
    super();
  }

  ngOnInit() {

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.isSideBarHidden = isLessThanXl);

    this.sideBarService.onToggle().subscribe(() => {
      this.isSideBarHidden = !this.isSideBarHidden
    })
  }

}
