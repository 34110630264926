import {createAction, props} from '@ngrx/store';
import { Message } from '../../api/models/message';
import { Room } from '../../api/models/room';

const LOAD_MESSAGES_REQUEST = '[Messages] Load Messages';
const LOAD_MESSAGES_SUCCESS = '[Messages] Load Messages Success';
const LOAD_MESSAGES_FAILURE = '[Messages] Load Messages Failure';


export const loadAllMessages = createAction(LOAD_MESSAGES_REQUEST, props<{ room: Room }>());

export const loadAllMessagesSuccess = createAction(
    LOAD_MESSAGES_SUCCESS,
    props<{ messages: Message[] }>(),
  );

  export const loadAllMessagesFailure = createAction(
    LOAD_MESSAGES_FAILURE,
    props<{ error: string }>(),
  );
