import { Injectable, Query } from "@angular/core";
import { FirestoreService } from "../base/firestore-service";
import { ClinicPatient } from "../models/clinic-patient";
import { Observable, of } from "rxjs";
import { orderBy } from "@angular/fire/firestore";
import { LocalStorageService } from "../local-storage/local-storage";

// TODO: remove usage of this service from components. use NgRX

@Injectable({
    providedIn: 'root'
})
export class PatientService {
    constructor(private db: FirestoreService, private localStorage: LocalStorageService) { }

    getPatientById(patientId: string): Observable<ClinicPatient> {
        return this.db.readDocument<ClinicPatient>(`clinic/${this.localStorage.getClinicID()}/patients`, patientId);
    }

    getAllPatients(): Observable<ClinicPatient[]> {
        return this.db.readCollection<ClinicPatient>(`clinic/${this.localStorage.getClinicID()}/patients`, orderBy('name', 'asc'))
    }
    updatePatientById(patientId: string, data: any): Observable<void> {
        return this.db.setDocument(`clinic/${this.localStorage.getClinicID()}/patients`, patientId, data, true)
    }
}

export class MockPatientService {
    getAllPatients(): Observable<ClinicPatient[]> {
      const dummyPatients: ClinicPatient[] =  mockPatientArray;
      return of(dummyPatients);
    }
  
    getPatientById(patientId: string): Observable<ClinicPatient> {
        return of(mockPatient);
    }
  }

  function createPatient(firstName: string, lastName): ClinicPatient {
    return   {
        id: 'testId',
        name: {
            firstName: firstName,
            lastName: lastName,
        },
        dateOfBirth: null,
        careTeamDetails: [],
        enrollmentStatus: {},
        ehrId: 'testEhrId',
      };
}

export const mockPatient = createPatient("John", "Doe")

export const mockPatientArray = [
      createPatient('First', 'Patient'),
      createPatient('Second', 'Patient'),
      createPatient('Third', 'Patient'),
    ];

export function mockOrderBy(field: string, direction: 'asc' | 'desc') {
    return {
      fieldPath: field,
      direction,
    };
  }

export function getMockClinicID() {
    return 'clinicId';
}