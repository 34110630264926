import { createReducer, on } from "@ngrx/store";
import {
  postBHIServiceLog,
  postBHIServiceLogFailure,
  postBHIServiceLogSuccess,
  loadBHIServiceLog,
  loadBHIServiceLogFailure,
  loadBHIServiceLogSuccess,
  deleteBHIServiceLog,
  deleteBHIServiceLogSuccess,
  deleteBHIServiceLogFailure,
} from "./bhi-service-logger.actions";
import { initialState } from "../auth/auth.reducer";

export const bhiServiceLogReducer = createReducer(
  initialState,

  on(postBHIServiceLog, (state) => ({
    ...state,
    loading: true,
    error: null,
    data: null,
  })),
  on(postBHIServiceLogSuccess, (state, { data }) => ({
    ...state,
    loading: false,
    error: null,
    data: data,
  })),
  on(postBHIServiceLogFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error: error,
    data: null,
  })),
  on(loadBHIServiceLog, (state) => ({
    ...state,
    loading: true,
    error: null,
    data: null,
  })),
  on(loadBHIServiceLogSuccess, (state, { data }) => ({
    ...state,
    loading: false,
    error: null,
    data: data
  })),
  on(loadBHIServiceLogFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error: error,
    data: null,
  })),

  on(deleteBHIServiceLog, (state) => ({
    ...state,
    deleteLoading: true,
    deleteError: "",
  })),

  on(deleteBHIServiceLogSuccess, (state) => ({
    ...state,
    deleteLoading: false,
  })),
  
  on(deleteBHIServiceLogFailure, (state, { error }) => ({
    ...state,
    deleteLoading: false,
    deleteError: error,
  })),
);
