<div class="login" fxLayout="row" fxLayoutAlign="space-around center">
  <div
    class="left-container"
    fxLayout="column"
    fxLayoutAlign="space-around center"
  >
    <div class="left-image">
      <img
        style="width: 100%"
        src="../../../assets/images/welcome_image.png"
        alt="Urban Health Logo"
      />
    </div>
    <div class="bottom-text">
      Power your Prior Authorization Workflows with one single platform. Seamless Integration, No New Dashboards
    </div>
  </div>

  <div class="login-container">
    <div class="branding">
      <div class="logo-image">
        <img
          src="../../../assets/images/frame_38183-1.png"
          alt="Urban Health Logo"
        />
      </div>
    </div>
    <div class="input-form">
      <div>
        <p class="header">Welcome</p>
      </div>
      <div class="input-fields">
        <label class="email">
          <span class="email-text">Email</span>
        </label>
        <div class="input-email">
          <input
            [(ngModel)]="user.email"
            type="text"
            placeholder="Enter your email"
            class="form-control"
            (keyup.enter)="login()"
            required
          />
        </div>
        <br />
        <div class="password-div">
          <label class="password">Password</label>
        </div>
        <div class="input-password">
          <input
            [(ngModel)]="user.password"
            type="password"
            placeholder="Enter your password"
            class="form-control"
            (keyup.enter)="login()"
            required
          />
        </div>
        <br />
        <div
          class="remember-forget"
          fxLayout="row"
          fxLayoutAlign="space-between start"
        >
          <div>
            <nb-checkbox
              status="success"
              [(ngModel)]="user.remember"
              [disabled]="false"
              >Remember me</nb-checkbox
            >
          </div>
          <div (click)="forgotPassword()">
            <label class="forgot-password">Forgot Password?</label>
          </div>
        </div>
        <label *ngIf="error" class="error">{{ error }}</label>
        <button [disabled]="isLoading" (click)="login()" class="login-button">
          <ng-container *ngIf="!isLoading">Log in</ng-container>
          <ng-container *ngIf="isLoading">
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span class="sr-only">Loading...</span>
          </ng-container>
        </button>
      </div>
    </div>
    <div class="gap" style="height: 5rem"></div>
    <div>
      Need Help?
      <span class="contact-us"
        ><a (click)="openTypeform()">
          Contact Us
        </a></span
      >
    </div>
  </div>
</div>
