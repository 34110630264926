import { Pipe, PipeTransform } from '@angular/core';
import { Timestamp } from 'firebase/firestore';
import { DatePipe } from '@angular/common';


@Pipe({
  name: 'FirestoreTimestampToDate'
})
export class FirestoreTimestampToDatePipe implements PipeTransform {
    transform(timestamp: Timestamp | null, format: string = 'yyyy-MM-dd HH:mm:ss'): string {
      if (!timestamp) {
        return '';
      }
  
      const date = timestamp.toDate();
  
      const datePipe = new DatePipe('en-US');
      return datePipe.transform(date, format);
    }
  }
