import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { BHIService } from "../../api/bhi/bhi.service";
import { catchError, map, mergeMap } from "rxjs/operators";
import { loadBHINotes, loadBHINotesFailure, loadBHINotesSuccess, saveBHINoteFailure, saveBHINoteRequest, saveBHINoteSuccess } from "./bhi-notes.actions";
import { of } from "rxjs";

@Injectable()
export class BHINotesEffects {
    constructor(private actions$: Actions, private bhiService: BHIService){}

    loadNotes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadBHINotes),
            mergeMap((action) =>
                this.bhiService.getNotes(action.patientId, action.conditionId).pipe(
                    map((notes) => loadBHINotesSuccess({ notes })),
                    catchError((error) => of(loadBHINotesFailure({ error: error.message }))),
                ),
            ),
        ),
    );

    saveNote$ = createEffect(() =>
        this.actions$.pipe(
            ofType(saveBHINoteRequest),
            mergeMap((action) =>
                this.bhiService.setNotes(action.patientId, action.conditionId, action.note).pipe(
                    map((note) => saveBHINoteSuccess({ message: "Note saved successfully" })),
                    catchError((error) => of(saveBHINoteFailure({ error: error.message }))),
                ),
            ),
        ),
    );
}