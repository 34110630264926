import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { map, take } from 'rxjs/operators';
import {
    collection,
    collectionData,
    doc,
    DocumentData,
    DocumentSnapshot,
    addDoc,
    deleteDoc,
    updateDoc,query,
    FirestoreInstances,
    UpdateData,
    setDoc,
    QueryConstraint,
    docSnapshots,
    getDoc
  } from '@angular/fire/firestore';
import { AuthInstances, user } from '@angular/fire/auth';

// TODO: remove usage of this service from components. use NgRX
@Injectable({
  providedIn: 'root'
})
export class FirestoreService {
  safeUid: string = "___unknown"

  constructor(private firestore: FirestoreInstances, private afAuth: AuthInstances) {
    this.observeLoggedInUser()
  }

  observeLoggedInUser() {
    user(this.afAuth[0])
    .pipe(take(1))
    .subscribe(user => {
      this.safeUid = user.uid
    })
  }

  public generateRandomId(): string {
    return doc(collection(this.firestore[1], "randomCollection")).id
  }

  createDocument<T>(collectionName: string, data: T): Observable<string> {
    const itemCollection = collection(this.firestore[1], collectionName);
    return from(addDoc(itemCollection, data)).pipe(map(docRef => docRef.id));
  }

 setDocument(collectionName, documentId, update, merge: boolean = true) {
  const itemDoc = doc(this.firestore[1], `${collectionName}/${documentId}`)
  return from(setDoc(itemDoc, update, { merge: merge }));
}

  readDocument<T>(collectionName: string, documentId: string): Observable<T | null> {
    const itemDoc = doc(this.firestore[1], `${collectionName}/${documentId}`);
    return from(docSnapshots(itemDoc)).pipe(
      map((docSnapshot: DocumentSnapshot<DocumentData>) => {
        if (docSnapshot.exists()) {
          return { id: documentId, ...(docSnapshot.data() as T) };
        } else {
          return null;
        }
      })
    );
  }

  readDocumentOnce<T>(collectionName: string, documentId: string): Observable<T | null> {
    const itemDoc = doc(this.firestore[1], `${collectionName}/${documentId}`);
    return from(getDoc(itemDoc)).pipe(
      map((docSnapshot: DocumentSnapshot<DocumentData>) => {
        if (docSnapshot.exists()) {
          return { id: documentId, ...(docSnapshot.data() as T) };
        } else {
          return null;
        }
      })
    );
  }

  updateDocument<T>(collectionName: string, documentId: string, dataToUpdate: UpdateData<T>): Observable<void> {
    const itemDoc = doc(this.firestore[1], `${collectionName}/${documentId}`);
    return from(updateDoc(itemDoc, { value: dataToUpdate}))
  }

  deleteDocument(collectionName: string, documentId: string): Observable<void> {
    const itemDoc = doc(this.firestore[1], `${collectionName}/${documentId}`);
    return from(deleteDoc(itemDoc));
  }

  readCollection<T>(collectionName: string, queryConstraint?: QueryConstraint): Observable<T[]> {
    const itemCollection = collection(this.firestore[1], collectionName);
    const q = query(itemCollection, queryConstraint);
    return collectionData(q, { idField: 'id'}) as Observable<T[]>
  }
}


