import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { BHIService } from "../../api/bhi/bhi.service";
import { catchError, map, mergeMap, tap } from "rxjs/operators";
import { postBHIServiceLog,
    postBHIServiceLogSuccess,
    postBHIServiceLogFailure,
    loadBHIServiceLog,
    loadBHIServiceLogSuccess,
    loadBHIServiceLogFailure,
    deleteBHIServiceLog,
    deleteBHIServiceLogSuccess,
    deleteBHIServiceLogFailure} from "./bhi-service-logger.actions";
import { of } from "rxjs";

@Injectable()
export class BHIServiceLogEffects {
    constructor(private actions$: Actions, private bhiService: BHIService){}
    setServiceTimerLog$ = createEffect(() =>
        this.actions$.pipe(
            ofType(postBHIServiceLog),
            mergeMap((action) =>
                this.bhiService.setTimeLogs(action.clinicId, action.patientId, action.timeLog).pipe(
                    map((data) => postBHIServiceLogSuccess({ data })),
                    catchError((error) => of(postBHIServiceLogFailure({ error: error.message }))),
                ),
            ),
        ),
    );

    getServiceTimerLog$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadBHIServiceLog),
            mergeMap((action) =>
                this.bhiService.getTimeLogs(action.clinicId, action.patientId).pipe(
                    map((data) => loadBHIServiceLogSuccess({ data })),
                    catchError((error) => of(loadBHIServiceLogFailure({ error: error.message }))),
                ),
            ),
        ),
    );

    deleteBHIServiceLog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteBHIServiceLog),
      mergeMap((action) =>
        this.bhiService.deleteTimeLogs(action.clinicId, action.patientId, action.timeLogId).pipe(
          map(() => deleteBHIServiceLogSuccess()),
          catchError((error) => of(deleteBHIServiceLogFailure({ error })))
        )
      )
    )
  );
}