import { Timestamp } from "@angular/fire/firestore";

// Convert a date to a string in the specified format
const monthLong = ["January","February","March","April","May","June","July","August","September","October","November","December"];
const monthShort = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
const dayLong = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
const dayShort = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export function formatDate(date: Date, format?: any): string {
  const options = {
    year: "numeric",
    month: "short",
    day: "2-digit",
  };

  return date.toLocaleString(undefined, { ...options, ...format });
}

// Get the full name of the month from a date
export function getMonthName(date: Date): string {
  const monthIndex = date.getMonth();
  return monthLong[monthIndex];
}

// Get the abbreviated name of the month from a date
export function getMonthAbbreviation(date: Date): string {
  const monthIndex = date.getMonth();
  return monthShort[monthIndex];
}

// Get the day of the month from a date
export function getDayOfMonth(date: Date): number {
  return date.getDate();
}

// Get the full name of the day of the week from a date
export function getDayOfWeek(date: Date): string {
  const dayIndex = date.getDay();
  return dayLong[dayIndex];
}

// Get the abbreviated name of the day of the week from a date
export function getDayOfWeekAbbreviation(date: Date): string {
  const dayIndex = date.getDay();
  return dayShort[dayIndex];
}

export function getCurrentMonthStart(){
  const today = new Date();
  const currentDate = new Date(today.getFullYear(), today.getMonth(), 1);
  return Timestamp.fromDate(currentDate);
}

// Example usage
//   const currentDate = new Date();
//   console.log('Formatted Date:', formatDate(currentDate, 'yyyy-MM-dd HH:mm:ss'));
//   console.log('Month:', getMonthName(currentDate));
//   console.log('Month Abbreviation:', getMonthAbbreviation(currentDate));
//   console.log('Day of Month:', getDayOfMonth(currentDate));
//   console.log('Day of Week:', getDayOfWeek(currentDate));
