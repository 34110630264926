import { createReducer, on } from '@ngrx/store';
import { getAllClinicUsers, getAllClinicUsersSuccess, getAllClinicUsersFailure, getCareTeam, getCareTeamSuccess, getCareTeamFailure, addCareTeam, addCareTeamSuccess, addCareTeamFailure, removeCareTeam, removeCareTeamSuccess, removeCareTeamFailure } from './care-team.actions';
import { ResponseState } from '../../api/models/response_state';

const initialState: ResponseState = {
  data: null,
  loading: false,
  error: null,
};

export const allClinicUsersReducer = createReducer(
  initialState,
  on(getAllClinicUsers, (state) => ({
    ...state,
    loading: true,
    error: null,
    data: null,
  })),
  on(getAllClinicUsersSuccess, (state, { AllClinicUsers }) => ({
    ...state,
    loading: false,
    error: null,
    data: AllClinicUsers,
  })),
  on(getAllClinicUsersFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error: error,
    data: null,
  })),
);

export const getCareTeamReducer = createReducer(
  initialState,
  on(getCareTeam, (state) => ({
    ...state,
    loading: true,
    error: null,
    data: null,
  })),
  on(getCareTeamSuccess, (state, { room }) => ({
    ...state,
    loading: false,
    error: null,
    data: room,
  })),
  on(getCareTeamFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error: error,
    data: null,
  })),
);

export const addCareTeamReducer = createReducer(
  initialState,
  on(addCareTeam, (state) => ({
    ...state,
    loading: true,
    error: null,
    data: null,
  })),
  on(addCareTeamSuccess, (state) => ({
    ...state,
    loading: false,
    error: null,
    data: true,
  })),
  on(addCareTeamFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error: error,
    data: null,
  })),
);

export const removeCareTeamReducer = createReducer(
  initialState,
  on(removeCareTeam, (state) => ({
    ...state,
    loading: true,
    error: null,
    data: null,
  })),
  on(removeCareTeamSuccess, (state) => ({
    ...state,
    loading: false,
    error: null,
    data: true,
  })),
  on(removeCareTeamFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error: error,
    data: null,
  })),
);
