
      <div class="reset-password">
          <nb-card class="reset-password-card">
              <nb-card-header class="card-header">
                  <span>RESET PASSWORD</span>
              </nb-card-header>
              <nb-card-body class="body">
                  <p class="header">Reset your RISA account</p>
                  <div class="input-fields">
                      <label class="email">
                          <span class="email-text">Email Address</span>
                      </label>
                      <div class="input-email">
                          <input [(ngModel)]="email" type="text" placeholder="Email Address" class="form-control"/>
                      </div>
                      <label *ngIf="message !== ''" [class]="messageClass">{{message}}</label>
                      <button nbButton [disabled]="false" status="success" (click) ="resetPassword()" class="reset-button">Send Reset Link</button>
                  </div>
              </nb-card-body>
          </nb-card>
      </div>
