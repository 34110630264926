import { createReducer, on } from '@ngrx/store';
import { getPatientRequest, getPatientRequestFailure, getPatientRequestSuccess, getAllPatientsRequest, getAllPatientsRequestFailure, getAllPatientsRequestSuccess } from './patient.actions';
import { initialState } from '../auth/auth.reducer';

export const getAllPatientsReducer = createReducer(
    initialState,
    on(getAllPatientsRequest, (state) => ({
        ...state,
        loading: true,
        error: null,
        data: null,
    })),
    on(getAllPatientsRequestSuccess, (state, { patients }) => ({
        ...state,
        loading: false,
        error: null,
        data: patients,
    })),
    on(getAllPatientsRequestFailure, (state, { error }) => ({
        ...state,
        loading: false,
        error: error,
        data: null,
    })),
);

export const getPatientReducer = createReducer(
    initialState,
    on(getPatientRequest, (state) => ({
        ...state,
        loading: true,
        error: null,
        data: null,
    })),
    on(getPatientRequestSuccess, (state, { patient }) => ({
        ...state,
        loading: false,
        error: null,
        data: patient,
    })),
    on(getPatientRequestFailure, (state, { error }) => ({
        ...state,
        loading: false,
        error: error,
        data: null,
    })),
);
