import { ACTIVITY_TYPES } from "../models/activity-types";
import {
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { COLORS } from "../../../@theme/oasis/colors/colors";
import { THEME_ICONS, ICON_SIZE } from "../../../../assets/icons/icons";
import {
  BUTTON_TYPE,
  BUTTON_SIZE,
} from "../../../ui/common/custom-button/button";

@Component({
  selector: "ngx-base",
  template: "",
})
export class BaseComponent implements OnInit, OnDestroy, OnChanges {
  ngOnInit(): void {
    this.onInit();
  }
  ngOnDestroy(): void {
    this.onDestroy();
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.onChanges(changes);
  }

  protected readonly Colors = COLORS;
  protected readonly CustomIcons = THEME_ICONS;
  protected readonly IconSize = ICON_SIZE;
  protected readonly ButtonType = BUTTON_TYPE;
  protected readonly ButtonSize = BUTTON_SIZE;
  protected readonly ActivityTypes = ACTIVITY_TYPES;

  onInit() {}
  onDestroy() {}
  onChanges(changes: SimpleChanges) {}
}
