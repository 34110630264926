import { createReducer, on } from '@ngrx/store';
import { getUserNotesFailure, getUserNotesRequest, getUserNotesSuccess, setUserNotesFailure, setUserNotesRequest, setUserNotesSuccess } from './user-notes.actions';
import { ResponseState } from '../../api/models/response_state';


const initialState: ResponseState = {
  data: null,
  loading: false,
  error: null,
};

export const userNotesReducer = createReducer(
  initialState,
  on(getUserNotesRequest, (state, { roomId }) => ({
    ...state,
    loading: true,
    error: null,
    data: null,
  })),
  on(getUserNotesSuccess, (state, { userNotes }) => ({
    ...state,
    loading: false,
    error: null,
    data: userNotes,
  })),
  on(getUserNotesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error: error,
    data: null,
  })),

  //set
  on(setUserNotesRequest, (state, { userNotes }) => ({
    ...state,
    loading: true,
    error: null,
    data: userNotes,
  })),
  on(setUserNotesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error: error,
    data: null,
  }))
);