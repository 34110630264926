import { Injectable } from "@angular/core";
import { FirestoreService } from "../base/firestore-service";
import { ActivityLog } from "../models/data-models";
import { Observable } from "rxjs";
import { LocalStorageService } from "../local-storage/local-storage";
import { orderBy } from "@angular/fire/firestore";

@Injectable(
    {
        providedIn: 'root'
    })
export class ActivityLogService {
    constructor (private db: FirestoreService, private localStorage: LocalStorageService) { }

    getAllActivityLogs(patientId: string): Observable<ActivityLog[]> {
        return this.db.readCollection<ActivityLog>(`/clinic/${this.localStorage.getClinicID()}/patients/${patientId}/activityLogs`, orderBy('createdAt', 'desc'));
    }

    setActivityLog(patientId: string, activityLog: ActivityLog): Observable<void> {
        activityLog.id = this.db.generateRandomId();

        return this.db.setDocument(
            `clinic/${this.localStorage.getClinicID()}/patients/${patientId}/activityLogs`,
            activityLog.id,
            activityLog
        );
    }
}