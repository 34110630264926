export const COLORS = {

  primaryGreen1: '#003D15',
  primaryGreen2: '#00521D',
  primaryGreen3: '#007028',
  primaryGreen4: '#008F32',
  primaryGreen5: '#1DB954',
  primaryGreen6: '#20CB5C',
  primaryGreen7: '#2CDD80',
  primaryGreen8: '#58E49B',
  primaryGreen9: '#84EBB5',
  primaryGreen10: '#CAF6DF',
  primaryGreen11: '#EEFCF4',

  primaryGray1: '#0F0F0F',
  primaryGray2: '#1F1F1F',
  primaryGray3: '#2E2E2E',
  primaryGray4: '#3D3D3D',
  primaryGray5: '#4D4D4D',
  primaryGray6: '#5C5C5C',
  primaryGray7: '#6B6B6B',
  primaryGray8: '#7A7A7A',
  primaryGray9: '#8A8A8A',
  primaryGray10: '#999999',
  primaryGray11: '#A8A8A8',
  primaryGray12: '#B8B8B8',
  primaryGray13: '#C7C7C7',
  primaryGray14: '#D6D6D6',
  primaryGray15: '#E6E6E6',
  primaryGray16: '#F5F5F5',
  white: '#FFFFFF',
  black: '#000000',

  secondaryPurple1: '#1A004D',
  secondaryPurple2: '#220066',
  secondaryPurple3: '#330099',
  secondaryPurple4: '#4B00CC',
  secondaryPurple5: '#661AFF',
  secondaryPurple6: '#7E33FF',
  secondaryPurple7: '#9E66FF',
  secondaryPurple8: '#C599FF',
  secondaryPurple9: '#E7CCFF',
  secondaryPurple10: '#F6E5FF',

  secondaryYellow1: '#474100',
  secondaryYellow2: '#665E00',
  secondaryYellow3: '#857A00',
  secondaryYellow4: '#A39600',
  secondaryYellow5: '#C2B200',
  secondaryYellow6: '#E0CE00',
  secondaryYellow7: '#FAE500',
  secondaryYellow8: '#FFEF3D',
  secondaryYellow9: '#FFF370',
  secondaryYellow10: '#FFF8AD',
  secondaryYellow11: '#FFFCD6',

  secondaryOrange1: '#8F3200',
  secondaryOrange2: '#C24400',
  secondaryOrange3: '#E65100',
  secondaryOrange4: '#EF6C00',
  secondaryOrange5: '#F57C00',
  secondaryOrange6: '#FB8C00',
  secondaryOrange7: '#FF9800',
  secondaryOrange8: '#FFA726',
  secondaryOrange9: '#FFB74D',
  secondaryOrange10: '#FFCC80',
  secondaryOrange11: '#FFE0B2',
  secondaryOrange12: '#FFF3E0',

  tertiaryRed1: '#7B0300',
  tertiaryRed2: '#A3000E',
  tertiaryRed3: '#CC0300',
  tertiaryRed4: '#F50400',
  tertiaryRed5: '#FF332E',
  tertiaryRed6: '#FF5452',
  tertiaryRed7: '#FF7370',
  tertiaryRed8: '#FF9999',
  tertiaryRed9: '#FFBDBD',
  tertiaryRed10: '#FFD6D6',

  tertiaryMagenta1: '#5C0048',
  tertiaryMagenta2: '#7A005F',
  tertiaryMagenta3: '#990077',
  tertiaryMagenta4: '#C20097',
  tertiaryMagenta5: '#E000AF',
  tertiaryMagenta6: '#FF1FCE',
  tertiaryMagenta7: '#FF47D7',
  tertiaryMagenta8: '#FF70E2',
  tertiaryMagenta9: '#FF99EB',
  tertiaryMagenta10: '#FFB8F1',
  tertiaryMagenta11: '#FFD6F7',
  tertiaryMagenta12: '#FFEBFB',

  tertiaryCyan1: '#002929',
  tertiaryCyan2: '#003D3D',
  tertiaryCyan3: '#005C5C',
  tertiaryCyan4: '#007A7A',
  tertiaryCyan5: '#008F8F',
  tertiaryCyan6: '#00B8B8',
  tertiaryCyan7: '#00E0E0',
  tertiaryCyan8: '#33FFFF',
  tertiaryCyan9: '#70FFFF',
  tertiaryCyan10: '#ADFFFF',
  tertiaryCyan11: '#D6FFFF',
  tertiaryCyan12: '#EBFFFF',

  tertiaryLime1: '#2A3D00',
  tertiaryLime2: '#375200',
  tertiaryLime3: '#537A00',
  tertiaryLime4: '#618F00',
  tertiaryLime5: '#7DB800',
  tertiaryLime6: '#9FEB00',
  tertiaryLime7: '#ACFF00',
  tertiaryLime8: '#C1FF3D',
  tertiaryLime9: '#D1FF70',
  tertiaryLime10: '#E5FFAD',
  tertiaryLime11: '#F3FFD6',

  tertiaryBlue1: '#002152',
  tertiaryBlue2: '#00317A',
  tertiaryBlue3: '#0041A3',
  tertiaryBlue4: '#0056D6',
  tertiaryBlue5: '#0066FF',
  tertiaryBlue6: '#297FFF',
  tertiaryBlue7: '#5297FF',
  tertiaryBlue8: '#7AAFFF',
  tertiaryBlue9: '#99C2FF',
  tertiaryBlue10: '#B8D4FF',
  tertiaryBlue11: '#D6E6FF',
  tertiaryBlue12: '#EAF2FF',

};
