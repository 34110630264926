import { NgModule } from '@angular/core';
import { AuthComponent } from './auth.component';
import { ThemeModule } from '../@theme/theme.module';
import { NbActionsModule, NbButtonModule, NbCardModule, NbCheckboxModule, NbInputModule, NbLayoutModule, NbIconModule } from '@nebular/theme';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { LoginComponent } from './login/login.component';
import { AuthRoutingModule } from './auth-routing.module';


@NgModule({
  declarations: [
    AuthComponent,
    ResetPasswordComponent,
    LoginComponent,
  ],
  imports: [
    ThemeModule,
    NbLayoutModule,
    NbCardModule,
    NbButtonModule,
    HttpClientModule,
    NbCardModule,
    NbInputModule,
    NbCheckboxModule,
    NbButtonModule,
    FormsModule,
    AuthRoutingModule,
    NbActionsModule,
  ],
  exports: [
    AuthComponent
  ]
})
export class AuthModule { }
