import { getCallAttemptRequest, getCallAttemptRequestFailure, getCallAttemptRequestSuccess, setCallAttemptRequest, setCallAttemptRequestFailure, setCallAttemptRequestSuccess } from './call-attempt.actions';
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from "rxjs/operators";
import { of } from "rxjs/internal/observable/of";
import { BHIService } from "../../api/bhi/bhi.service";
import { CallLog } from "../../api/models/data-models"; 
import { Timestamp } from "@angular/fire/firestore";


@Injectable()
export class CallAttemptEffects {
    constructor(private actions$: Actions, private bhiService: BHIService) { }

    setCallAttempt$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setCallAttemptRequest),
            mergeMap((action) => {
                const callLog: CallLog = {
                    createdAt: Timestamp.now(),
                    callAttemptStatus: action.isCallAttempted ? "success" : "missed",
                    nextFollowUp: Timestamp.fromDate(action.followUpDate)
                };
                return this.bhiService.setCallAttempts("clinicID", "patientID", callLog).pipe(
                    map(() => setCallAttemptRequestSuccess({ message: "Saved call attempt successfully" })),
                    catchError((error) => of(setCallAttemptRequestFailure({ error: error.message }))),
                )
            }
            )
        )
    );

    getCallAttempt$ = createEffect(() =>
    this.actions$.pipe(
        ofType(getCallAttemptRequest),
        mergeMap((action) =>
          this.bhiService.getCallAttempts(action.clinicId, action.patientId).pipe(
            map((callLog) => getCallAttemptRequestSuccess({ callLog })),
            catchError((error) => of(getCallAttemptRequestFailure({ error: error.message }))),
          ),
        ),
      ),
    )
}