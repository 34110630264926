import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { ActivityLogService } from "../../api/activity-log/activity-log.service";
import { catchError, map, mergeMap } from "rxjs/operators";
import { activityLogsFetchFailure, activityLogsFetchRequest, activityLogsFetchSuccess, activityLogsWriteFailure, activityLogsWriteRequest, activityLogsWriteSuccess } from "./activity-logs.actions";
import { of } from "rxjs";

@Injectable()
export class ActivityEffects {
    constructor (private actions$: Actions, private activityLogService: ActivityLogService) { }
    loadAllLogs$ = createEffect(() =>
        this.actions$.pipe(
            ofType(activityLogsFetchRequest),
            mergeMap((action) =>
                this.activityLogService.getAllActivityLogs(action.patientId).pipe(
                    map((activityLogs) => activityLogsFetchSuccess({ activityLogs }),
                        catchError((error) => of(activityLogsFetchFailure({ error: error.message }))),

                    )
                )
            )
        )
    )

    saveLog$ = createEffect(() =>
        this.actions$.pipe(
            ofType(activityLogsWriteRequest),
            mergeMap((action) =>
                this.activityLogService.setActivityLog(action.patientId, action.activityLog).pipe(
                    map(() => activityLogsWriteSuccess(),
                        catchError((error) => of(activityLogsWriteFailure({ error: error.message }))),

                    )
                )
            )
        )
    )
}
