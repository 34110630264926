import { Injectable } from "@angular/core";
import {
  AuthInstances,
  sendPasswordResetEmail,
  signInAnonymously,
  signInWithEmailAndPassword,
  user,
} from "@angular/fire/auth";
import { doc, docData, FirestoreInstances } from "@angular/fire/firestore";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { from, Observable, of, ReplaySubject } from "rxjs";
import {
  map,
  mergeMap,
  switchMap,
  take,
  concatMap,
  catchError,
  finalize,
} from "rxjs/operators";
import { UserDetails } from "../models/data-models";
import { LocalStorageService } from "../local-storage/local-storage";

// TODO: make it readable, remove unused and unnecessary code
// TODO: remove usage of this service from components. use NgRX

@Injectable({
  providedIn: "root",
})
export class AuthService implements CanActivate {
  //TODO remove if not needed
  loggedInUser$ = new ReplaySubject<UserDetails>(1);
  safeUid: string = "___unknown";

  constructor(
    private router: Router,
    private db: FirestoreInstances,
    private afAuth: AuthInstances,
    private localStorage: LocalStorageService
  ) {
    user(this.afAuth[0])
      .pipe(take(1))
      .pipe(
        mergeMap((user) => {
          this.safeUid = user.uid;
          if (user) return docData(doc(this.db[0], `user/${user.uid}`));
          else return of(undefined);
        })
      )
      .subscribe((userDetails) => {
        this.loggedInUser$.next(userDetails as UserDetails);
        this.localStorage.setItem(
          this.localStorage.USER_DETAILS_LOCAL,
          userDetails
        );
      });
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    this.isLoggedIn().then((value) => {
      if (value === false) {
        this.router.navigate(["auth", "login"]);
      }
    });
    return this.isLoggedIn();
  }

  login(email: string, password: string) {
    let errorMessage: ReplaySubject<string> = new ReplaySubject(1);

    signInWithEmailAndPassword(this.afAuth[1], email, password)
      .then((userCredential) => {
        signInAnonymously(this.afAuth[1]);

        docData(doc(this.db[0], `user/${userCredential.user.uid}`))
          .pipe(take(1))
          .subscribe((userDetails) => {
            this.loggedInUser$.next(userDetails as UserDetails);
            this.localStorage.setItem(
              this.localStorage.USER_DETAILS_LOCAL,
              userDetails
            );
          });
        this.router.navigate(["pages"]);
      })
      .catch((error) => {
        errorMessage.next("Invalid email and/or password");
      });

    return errorMessage;
  }

  loginViaRedux(email: string, password: string): Observable<any> {
    return from(
      signInWithEmailAndPassword(this.afAuth[0], email, password)
    ).pipe(
      switchMap((userCredential) => {
        signInAnonymously(this.afAuth[1]);
        return docData(doc(this.db[0], `user/${userCredential.user.uid}`)).pipe(
          take(1)
        );
      })
    );
  }

  loginWithNoReturn() {}
  isLoggedIn() {
    return user(this.afAuth[0])
      .pipe(take(1))
      .pipe(
        map((user) => {
          if (user) return true;
          else return false;
        })
      )
      .toPromise();
  }

  logOut() {
    return from(this.afAuth[0].signOut()).pipe(
      concatMap(() => from(this.afAuth[1].signOut())), // Sign out from both auth instances
      concatMap(() => {
        this.loggedInUser$.complete();
        this.localStorage.removeItem(this.localStorage.USER_DETAILS_LOCAL);
        this.router.navigate(["auth", "login"]);
        return of(true);
      }),
      catchError((error) => {
        console.error("Logout error:", error);
        return of(false);
      })
    );
  }

  resetPassword(email: string) {
    let message: ReplaySubject<any> = new ReplaySubject(1);

    sendPasswordResetEmail(this.afAuth[0], email)
      .then((value) => {
        message.next({
          status: "success",
          message: `Reset link sent successfully to ${email}`,
        });
      })
      .catch((error) => {
        message.next({
          status: "error",
          message: `Something went wrong. Please try again after sometime.`,
        });
      });

    return message;
  }
}
