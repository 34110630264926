import { createReducer, on } from "@ngrx/store";
import { initialState } from "../auth/auth.reducer";
import { activityLogsFetchFailure, activityLogsFetchRequest, activityLogsFetchSuccess, activityLogsWriteFailure, activityLogsWriteRequest, activityLogsWriteSuccess } from "./activity-logs.actions";


export const activityLogReducer = createReducer(
    initialState,
    on(activityLogsFetchRequest, (state) => ({
        ...state,
        loading: true,
        error: null,
        data: null,
    })),
    on(activityLogsFetchSuccess, (state, { activityLogs }) => ({
        ...state,
        loading: false,
        error: null,
        data: activityLogs,
    })),
    on(activityLogsFetchFailure, (state, { error }) => ({
        ...state,
        loading: false,
        error: error,
        data: null,
    }))
)

export const activityLogWriteReducer = createReducer(
    initialState,
    on(activityLogsWriteRequest, (state) => ({
        ...state,
        loading: true,
        error: null,
        data: null,
    })),
    on(activityLogsWriteSuccess, (state, { }) => ({
        ...state,
        loading: false,
        error: null,
        data: 'success',
    })),
    on(activityLogsWriteFailure, (state, { error }) => ({
        ...state,
        loading: false,
        error: error,
        data: null,
    }))
)