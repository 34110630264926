import { createReducer, on } from '@ngrx/store';
import { getConditionRequest, getConditionRequestFailure, getConditionRequestSuccess, getAllConditionsRequest, getAllConditionsRequestSuccess, getAllConditionsRequestFailure } from './condition.actions';
import { ResponseState } from '../../api/models/response_state';

const initialState: ResponseState = {
  data: null,
  loading: false,
  error: null,
};

export const getConditionReducer = createReducer(
  initialState,
  on(getConditionRequest, (state) => ({
    ...state,
    loading: true,
    error: null,
    data: null,
  })),
  on(getConditionRequestSuccess, (state, { condition }) => ({
    ...state,
    loading: false,
    error: null,
    data: condition,
  })),
  on(getConditionRequestFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error: error,
    data: null,
  })),
);

export const getAllConditionsReducer = createReducer(
  initialState,
  on(getAllConditionsRequest, (state) => ({
    ...state,
    loading: true,
    error: null,
    data: null
  })),
  on(getAllConditionsRequestSuccess, (state, { conditions }) => ({
    ...state,
    loading: false,
    error: null,
    data: conditions
  })),
  on(getAllConditionsRequestFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error: error,
    data: null
  })),

)