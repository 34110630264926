import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { AuthService } from "../../api/authentication/auth.service";
import {
  loginUser,
  loginUserSuccess,
  loginUserFailure,
  logoutUser,
  logoutUserSuccess,
  logoutUserFailure,
  resetPassword,
  resetPasswordSuccess,
  resetPasswordFailure,
} from "./auth.actions";
import { LocalStorageService } from "../../api/local-storage/local-storage";
import { UserDetails } from "../../api/models/data-models";

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private localStorage: LocalStorageService
  ) {}

  // This loadUser$ subscription is managed by ngrx and will be cleaned automatically.
  loadUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginUser),
      mergeMap((action) =>
        this.authService
          .loginViaRedux(
            action.loginRequest.email,
            action.loginRequest.password
          )
          .pipe(
            map((user: UserDetails) => {
              this.localStorage.setItem(
                this.localStorage.USER_DETAILS_LOCAL,
                user
              );
              this.authService.loggedInUser$.next(user);
              return loginUserSuccess({ user });
            }),
            catchError((error) =>
              of(loginUserFailure({ error: error.message }))
            )
          )
      )
    )
  );

  logoutUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logoutUser),
      mergeMap(() =>
        this.authService.logOut().pipe(
          map(() =>
            logoutUserSuccess({ message: "User logged out successfully" })
          ),
          catchError((error) => of(logoutUserFailure({ error: error.message })))
        )
      )
    )
  );

  resetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resetPassword),
      mergeMap((action) =>
        this.authService.resetPassword(action.email).pipe(
          map(() => resetPasswordSuccess({ message: "Hello" })),
          catchError((error) =>
            of(resetPasswordFailure({ error: error.message }))
          )
        )
      )
    )
  );
}
