import { Injectable } from "@angular/core";
import { FirestoreService } from "../base/firestore-service";
import { CallLog, Condition, Icd10, Note, TimeLog } from "../models/data-models";
import { Observable } from "rxjs";
import { orderBy, where } from "@angular/fire/firestore";
import { getCurrentMonthStart } from "../../utils/date-utils";
import { LocalStorageService } from "../local-storage/local-storage";

// TODO: remove usage of this service from components. use NgRX
@Injectable({
    providedIn: 'root'
})
export class BHIService {
    constructor (private db: FirestoreService, private localStorage: LocalStorageService) { }

    getConditionById(bhiId: string): Observable<Condition> {
        return this.db.readDocument<Condition>(`admin_dashboard_config/assessment/condition/`, bhiId);
    }

    getAllBHIConditions(patientId: string): Observable<Condition[]> {
        return this.db.readCollection<Condition>(`/clinic/${this.localStorage.getClinicID()}/patients/${patientId}/bhiConditions/`, orderBy("createdAt", "desc"))
    }

    // TODO: Change this to use the clinicId, patientId, and conditionId
    getNotes(patientId: string, conditionId: string): Observable<Note[]> {
        return this.db.readCollection<Note>(

            `clinic/${this.localStorage.getClinicID()}/patients/${patientId}/bhiConditions/${conditionId}/notes/`,
            orderBy('updatedAt', 'desc')
        );
    }

    setNotes(patientId: string, conditionId: string, note: Note): Observable<void> {
        note.id = this.db.generateRandomId();
        note.updatedBy = this.db.safeUid;
        return this.db.setDocument(
            `clinic/${this.localStorage.getClinicID()}/patients/${patientId}/bhiConditions/${conditionId}/notes`,
            note.id,
            note
        );
    }


    getICDCodes(): Observable<Icd10[]> {
        return this.db.readCollection<Icd10>(`icd10`, orderBy('order', 'asc'));
    }

    getTimeLogs(clinicId: string, patientId: string): Observable<TimeLog[]> {
        return this.db.readCollection<TimeLog>(
            `clinic/${clinicId}/patients/${patientId}/timeLog`,
            where('date', '>=', getCurrentMonthStart())
        );
    }

    setTimeLogs(clinicId: string, patientId: string, timeLog: TimeLog): Observable<void> {
        let _id = this.db.generateRandomId();
        timeLog = { ...timeLog, id: _id }
        return this.db.setDocument(
            `clinic/${clinicId}/patients/${patientId}/timeLog`,
            timeLog.id,
            timeLog
        );
    }

    deleteTimeLogs(clinicId: string, patientId: string, timeLogId: string): Observable<void> {
        return this.db.deleteDocument(
            `clinic/${clinicId}/patients/${patientId}/timeLog`,
            timeLogId
        )
    }

    getCallAttempts(clinicId: string, patientId: string): Observable<CallLog[]> {
        return this.db.readCollection<CallLog>(
            `clinic/${clinicId}/patients/${patientId}/callLog`,
            orderBy('createdAt', 'desc')
        );
    }

    setCallAttempts(clinicId: string, patientId: string, callLog: CallLog): Observable<void> {
        const id = this.db.generateRandomId();
        return this.db.setDocument(
            `clinic/${clinicId}/patients/${patientId}/callLog`,
            id,
            callLog
        );
    }


    getCarePlanBuilderIds(conditionId: string): Observable<any> {

        return this.db.readCollection<any>(
            `admin_dashboard_config/assessment/condition/${conditionId}/carePlanBuilder`,
            orderBy('order', 'asc')
        );
    }


    getCarePlanData(carePlanId: string, carePlanEntity: string, patientId: string, conditionId: string): Observable<any> {
        return this.db.readCollection<any>(
            `clinic/${this.localStorage.getClinicID()}/patients/${patientId}/bhiConditions/${conditionId}/carePlan/${carePlanId}/${carePlanEntity}`,
            orderBy('order', 'desc')
        );
    }


    getCarePlan(patientId: string, conditionId: string): Observable<any> {
        return this.db.readCollection<any>(
            `/clinic/${this.localStorage.getClinicID()}/patients/${patientId}/bhiConditions/${conditionId}/carePlan`,
            orderBy('createdAt', 'desc'),
        );
    }
}
