import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from "@angular/core";

import { Router } from "@angular/router";
import { BaseComponent } from "../../../@core/api/base/base-component";

@Component({
  selector: "ngx-header",
  styleUrls: ["./header.component.scss"],
  templateUrl: "./header.component.html",
})
export class HeaderComponent
  extends BaseComponent
  implements AfterViewInit, OnInit
{
  lastPortion: string = "";

  @Output() routerPath: EventEmitter<string> = new EventEmitter<string>();
  constructor(private router: Router) {
    super();
  }

  ngOnInit(): void {
    this.getCurrentUrl();
  }

  ngAfterViewInit(): void {
    this.selectActiveRoute(this.lastPortion);
  }

  emitRoutePath(route: string) {
    this.routerPath.emit(route);
    this.selectActiveRoute(route);
  }

  selectActiveRoute(route: string = "") {
    const navLinks = document.querySelectorAll(".nav-links");

    const el = document.getElementsByClassName(`nav-links ${route}`)[0];

    if (el) {
      navLinks.forEach((link) => {
        link.classList.remove("active");
      });
      el.classList.add("active");
    }
  }

  getCurrentUrl() {
    const currentUrl = this.router.url;
    const urlSegments = currentUrl.split("/");
    this.lastPortion = urlSegments[urlSegments.length - 1];
    this.selectActiveRoute(this.lastPortion);
  }
}
