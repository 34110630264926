import { createReducer, on } from '@ngrx/store';
import { loadAllRooms, loadAllRoomsSuccess, loadAllRoomsFailure } from './room.actions';
import { ResponseState } from '../../api/models/response_state';

const initialState: ResponseState = {
  data: null,
  loading: false,
  error: null,
};

export const roomsReducer = createReducer(
  initialState,
  on(loadAllRooms, (state) => ({
    ...state,
    loading: true,
    error: null,
    data: null,
  })),
  on(loadAllRoomsSuccess, (state, { rooms }) => ({
    ...state,
    loading: false,
    error: null,
    data: rooms,
  })),
  on(loadAllRoomsFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error: error,
    data: null,
  })),
);
