import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { favoriteClicked, firestoreDataSet, loadAllRooms, loadAllRoomsFailure, loadAllRoomsSuccess } from "./room.actions";
import { catchError, map, mergeMap } from "rxjs/operators";
import { of } from "rxjs/internal/observable/of";
import { ChatService } from "../../api/chat/chat.service";

@Injectable()
export class GetAllRoomEffects {

  constructor(private actions$: Actions, private chatService: ChatService) {}

  loadRooms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAllRooms),
      mergeMap((action) =>
        this.chatService.getAllRooms(action.userId).pipe(
          map((rooms) => loadAllRoomsSuccess({ rooms })),
          catchError((error) => of(loadAllRoomsFailure({ error: error.message }))),
        ),
      ),
    ),
  );    
}


@Injectable()
export class RoomEffects {
  constructor (private actions$: Actions, private chatService: ChatService) { }

  favRoom$ = createEffect(() =>
    this.actions$.pipe(ofType(favoriteClicked),
      mergeMap((action) =>
        this.chatService.updatePriorityState(action.roomID, action.isPriority).pipe(
          map(() => firestoreDataSet())
        )
      )
    )
  )
}