import { createAction, props } from '@ngrx/store';
import { Icd10 } from '../../api/models/data-models';

const GET_ICD_CODE_REQUEST = '[Icd10] Load ICD Code';
const GET_ICD_CODE_REQUEST_SUCCESS = '[Icd10] Load ICD Code Request Success';
const GET_ICD_CODE_REQUEST_FAILURE = '[Icd10] Load ICD Code Request Failure';

export const getICDCodeRequest = createAction(GET_ICD_CODE_REQUEST);

export const getICDCodeRequestSuccess = createAction(GET_ICD_CODE_REQUEST_SUCCESS, props<{ icd10: Icd10[] }>());

export const getICDCodeRequestFailure = createAction(GET_ICD_CODE_REQUEST_FAILURE, props<{ error: string }>());