import { BaseComponent } from "./../../@core/api/base/base-component";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { UserDetails } from "../../@core/api/models/data-models";
import { loginUser } from "../../@core/state/auth/auth.actions";
import { ResponseState } from "../../@core/api/models/response_state";
import { isEmailValid } from "../../@core/utils/string-utils";
import { createSlider } from "@typeform/embed";
import "@typeform/embed/build/css/slider.css"


@Component({
  selector: "ngx-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent extends BaseComponent implements OnInit {
  isLoading: boolean;
  error: string | null;
  userDetails: UserDetails | null;
  user: {
    email: string;
    password: string;
    remember: boolean;
  };

  constructor(
    private router: Router,
    private store: Store<{ user: ResponseState }>
  ) {
    super();
  }

  ngOnInit(): void {
    this.initializeUser();
    this.listenToUserFromStore();
  }

  initializeUser() {
    this.user = {
      email: "",
      password: "",
      remember: true,
    };
  }

  listenToUserFromStore() {
    this.store
      .select((state) => state.user)
      .subscribe((state: ResponseState) => {
        this.isLoading = state.loading;
        this.error = this.parseLoginError(state.error);
        this.userDetails = state.data;
        if (this.userDetails) {
          this.router.navigate(["pages"]);
        }
      });
  }

  parseLoginError(error: string | null) {
    if (!error) return null;
    if (error.toString().includes("auth/user-not-found")) {
      return "Email not found";
    } else if (error.includes("auth/wrong-password")) {
      return "Invalid password";
    }
  }

  login() {
    if (isEmailValid(this.user.email)) {
      this.store.dispatch(
        loginUser({
          loginRequest: {
            email: this.user.email,
            password: this.user.password,
            remember: this.user.remember,
          },
        })
      );
    } else {
      this.error = "Please enter a valid email address";
    }
  }

  forgotPassword() {
    this.router.navigate(["auth", "reset-password"]);
  }

  openTypeform() {
    createSlider("WRDLbhJg").open()
  }
}
