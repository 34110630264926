import { createAction, props } from "@ngrx/store";
import { Note } from "../../api/models/data-models";


const SAVE_BHI_NOTE_REQUEST = '[BHI Notes] Save Note Request';
const SAVE_BHI_NOTE_SUCCESS = '[BHI Notes] Save Note Success';
const SAVE_BHI_NOTE_FAILURE = '[BHI Notes] Save Note Failure';

export const saveBHINoteRequest = createAction(SAVE_BHI_NOTE_REQUEST, props<{ patientId: string, conditionId: string, note: Note }>());
export const saveBHINoteSuccess = createAction(SAVE_BHI_NOTE_SUCCESS,props<{ message: String }>(),);
export const saveBHINoteFailure = createAction(SAVE_BHI_NOTE_FAILURE,props<{ error: string }>(),);

const LOAD_BHI_NOTES_REQUEST = '[BHI Notes] Load Notes Request';
const LOAD_BHI_NOTES_SUCCESS = '[BHI Notes] Load Notes Success';
const LOAD_BHI_NOTES_FAILURE = '[BHI Notes] Load Notes Failure';

export const loadBHINotes = createAction(LOAD_BHI_NOTES_REQUEST, props<{ patientId: string, conditionId: string }>());
export const loadBHINotesSuccess = createAction(LOAD_BHI_NOTES_SUCCESS, props<{ notes: Note[] }>());
export const loadBHINotesFailure = createAction(LOAD_BHI_NOTES_FAILURE, props<{ error: string }>());