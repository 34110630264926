import { createReducer, on } from "@ngrx/store";
import {
  loginUser,
  loginUserSuccess,
  loginUserFailure,
  logoutUser,
  logoutUserFailure,
  logoutUserSuccess,
} from "./auth.actions";
import { ResponseState } from "../../api/models/response_state";

export const initialState: ResponseState = {
  data: null,
  loading: false,
  error: null,
};

export const userReducer = createReducer(
  initialState,
  on(loginUser, (state) => ({
    ...state,
    loading: true,
    error: null,
    data: null,
  })),
  on(loginUserSuccess, (state, { user }) => ({
    ...state,
    loading: false,
    error: null,
    data: user,
  })),
  on(loginUserFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error: error,
    data: null,
  }))
);

export const resetPasswordReducer = createReducer(
  initialState,
  on(logoutUser, (state) => ({
    ...state,
    loading: true,
    error: null,
    data: null,
  })),
  on(logoutUserSuccess, (state, { message }) => ({
    ...state,
    loading: false,
    error: null,
    data: message,
  })),
  on(logoutUserFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error: error,
    data: null,
  }))
);

export const logOutUserReducer = createReducer(
  initialState,
  on(logoutUser, (state) => ({
    ...state,
    loading: true,
    error: null,
    data: null,
  })),
  on(logoutUserSuccess, (state, { message }) => ({
    ...state,
    loading: false,
    error: null,
    data: message,
  })),
  on(logoutUserFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error: error,
    data: null,
  }))
);
