import { createAction, props } from '@ngrx/store';
import { Condition } from '../../api/models/data-models';

const GET_CONDITION_REQUEST = '[Condition] Get Condition Request';
const GET_CONDITION_REQUEST_SUCCESS = '[Condition] Get Condition Request Success';
const GET_CONDITION_REQUEST_FAILURE = '[Condition] Get Condition Request Failure';

export const getConditionRequest = createAction(GET_CONDITION_REQUEST, props<{ bhiId: string }>());
export const getConditionRequestSuccess = createAction(GET_CONDITION_REQUEST_SUCCESS, props<{ condition: Condition }>());
export const getConditionRequestFailure = createAction(GET_CONDITION_REQUEST_FAILURE, props<{ error: string }>());

const GET_ALL_CONDITIONS_REQUEST = '[Condition] Get all Conditions Request';
const GET_ALL_CONDITIONS_REQUEST_SUCCESS = '[Condition] Get all Conditions Request Success';
const GET_ALL_CONDITIONS_REQUEST_FAILURE = '[Condition] Get all Conditions Request Failure';

export const getAllConditionsRequest = createAction(GET_ALL_CONDITIONS_REQUEST, props<{patientId: string }>())
export const getAllConditionsRequestSuccess = createAction(GET_ALL_CONDITIONS_REQUEST_SUCCESS, props<{ conditions: Condition[] }>())
export const getAllConditionsRequestFailure = createAction(GET_ALL_CONDITIONS_REQUEST_FAILURE, props<{ error: string }>())

