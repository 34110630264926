import { createAction, props } from '@ngrx/store';
import { UserNote } from '../../api/models/room';

const GET_USER_NOTES_REQUEST = '[User] Get User Notes Request';
const GET_USER_NOTES_SUCCESS = '[User] Get User Notes Success';
const GET_USER_NOTES_FAILURE = '[User] Get User Notes Failure';
const SET_USER_NOTES_REQUEST = '[User] Set User Notes Request';
const SET_USER_NOTES_SUCCESS = '[User] Set User Notes Success';
const SET_USER_NOTES_FAILURE = '[User] Set User Notes Failure';

export const getUserNotesRequest = createAction(GET_USER_NOTES_REQUEST, props<{ roomId: string }>());

export const getUserNotesSuccess = createAction(GET_USER_NOTES_SUCCESS, props<{ userNotes: UserNote[] }>());

export const getUserNotesFailure = createAction(GET_USER_NOTES_FAILURE, props<{ error: string }>());

export const setUserNotesRequest = createAction(SET_USER_NOTES_REQUEST, props<{ roomId: string, userNotes: UserNote }>());

export const setUserNotesSuccess = createAction(SET_USER_NOTES_SUCCESS);

export const setUserNotesFailure = createAction(SET_USER_NOTES_FAILURE, props<{ error: string }>());