import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap } from "rxjs/operators";
import { of } from "rxjs/internal/observable/of";
import { getUserNotesFailure, getUserNotesRequest, getUserNotesSuccess, setUserNotesFailure, setUserNotesRequest, setUserNotesSuccess } from "./user-notes.actions";
import { ChatService } from "../../api/chat/chat.service";


@Injectable()
export class NotesEffects {
  constructor (private actions$: Actions, private chatService: ChatService) { }

  loadUserNotes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserNotesRequest),
      mergeMap((action) =>
        this.chatService.getUserNotes(action.roomId).pipe(
          map((userNotes) => {
            // console.log(`============= line 19, ${userNotes.entries}`)
            return getUserNotesSuccess({ userNotes })
          },
            catchError((error) => of(getUserNotesFailure({ error: error.message }))),
          ),
        ),
      ),
    ));

  setUserNotes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setUserNotesRequest),
      mergeMap((action) =>
        this.chatService.setuserNotes(action.roomId, action.userNotes).pipe(
          map((userNotes) => setUserNotesSuccess()),
          catchError((error) => of(setUserNotesFailure({ error: error.message })))
        )
      )
    )
  );
}