import { createAction, props } from "@ngrx/store";
import { TimeLog } from "../../api/models/data-models";

const POST_BHI_SERVICE_LOGGERS_REQUEST = '[BHI Service Log] Load Service Log Request';
const POST_BHI_SERVICE_LOGGERS_SUCCESS = '[BHI Service Log] Load Service Log Success';
const POST_BHI_SERVICE_LOGGERS_FAILURE = '[BHI Service Log] Load Service Log Failure';

export const postBHIServiceLog = createAction(POST_BHI_SERVICE_LOGGERS_REQUEST, props<{ clinicId: string, patientId: string,timeLog: TimeLog }>());
export const postBHIServiceLogSuccess = createAction(POST_BHI_SERVICE_LOGGERS_SUCCESS, props<{ data:any }>());
export const postBHIServiceLogFailure = createAction(POST_BHI_SERVICE_LOGGERS_FAILURE, props<{ error: string }>());


const LOAD_BHI_SERVICE_LOGGERS_REQUEST = '[BHI Service Log] Load Service Log Request';
const LOAD_BHI_SERVICE_LOGGERS_SUCCESS = '[BHI Service Log] Load Service Log Success';
const LOAD_BHI_SERVICE_LOGGERS_FAILURE = '[BHI Service Log] Load Service Log Failure';

// TODO: remove clinic Ids from all
export const loadBHIServiceLog = createAction(LOAD_BHI_SERVICE_LOGGERS_REQUEST, props<{ clinicId: string, patientId: string }>());
export const loadBHIServiceLogSuccess = createAction(LOAD_BHI_SERVICE_LOGGERS_SUCCESS, props<{ data:any }>());
export const loadBHIServiceLogFailure = createAction(LOAD_BHI_SERVICE_LOGGERS_FAILURE, props<{ error: string }>());

const DELETE_BHI_SERVICE_LOG_REQUEST = '[BHI Service Log] Delete Service Log Request';
const DELETE_BHI_SERVICE_LOG_SUCCESS = '[BHI Service Log] Delete Service Log Success';
const DELETE_BHI_SERVICE_LOG_FAILURE = '[BHI Service Log] Delete Service Log Failure';

export const deleteBHIServiceLog = createAction(DELETE_BHI_SERVICE_LOG_REQUEST, props<{ clinicId: string, patientId: string, timeLogId: string }>());
export const deleteBHIServiceLogSuccess = createAction(DELETE_BHI_SERVICE_LOG_SUCCESS);
export const deleteBHIServiceLogFailure = createAction(DELETE_BHI_SERVICE_LOG_FAILURE, props<{ error: string }>());
