import { createReducer, on } from '@ngrx/store';
import { getICDCodeRequest, getICDCodeRequestFailure, getICDCodeRequestSuccess } from './icd-codes.actions';
import { ResponseState } from '../../api/models/response_state';

const initialState: ResponseState = {
    data: null,
    loading: false,
    error: null,
};

export const getICDCodeReducer = createReducer(
    initialState,
    on(getICDCodeRequest, (state) => ({
      ...state,
      loading: true,
      error: null,
      data: null,
    })),
    on(getICDCodeRequestSuccess, (state, { icd10 }) => ({
      ...state,
      loading: false,
      error: null,
      data: icd10,
    })),
    on(getICDCodeRequestFailure, (state, { error }) => ({
      ...state,
      loading: false,
      error: error,
      data: null,
    })),
  );
  