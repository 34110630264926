import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "replaceNameInMessage",
})
export class ReplaceNameInMessagePipe implements PipeTransform {
  transform(value: string, args1: string): string {
    let regEx = new RegExp("#fName", "g");
    return value.replace(regEx, args1);
  }
}
