<!-- <div class="footer-container">
    <div class="settings-container" fxLayout="row">
        <div class="icon-container" fxFlex="30%">
            <nb-icon icon="settings-outline" pack="oasis"></nb-icon>
        </div>
        <div class="text-container" fxFlex="70%">
            <span>Settings</span>
        </div>
    </div>
    <div class="profile-container" [nbContextMenu]="userMenu">
        <nb-actions size="small">
            <nb-action *nbIsGranted="['view', 'user']">
                <div class="icon-container">
                    <nb-user [onlyPicture]="true" [name]="user?.name" [picture]="user?.imageUrl" size="small">
                    </nb-user>
                </div>
                <div class="name-container">{{user?.name}}</div>
            </nb-action>
        </nb-actions>
    </div>
    <div class="uh-logo-container" fxLayout="row wrap">
        <div class="icon-container" fxFlex="30%">
            <nb-icon icon="uh-logo" pack="oasis"></nb-icon>
        </div>
        <div class="text-container" fxFlex="70%">
            Test
        </div>
    </div>
</div> -->

<div class="footer-container">
    <div class="settings-container">
        <div class="settings-container_icon">
            <nb-icon icon="settings-outline" pack="oasis"></nb-icon>
        </div>
        <div [ngClass]="this.isSideBarHidden ? 'settings-container_text hide':'settings-container_text'">
            <h12-regular [color]="Colors.primaryGray4">Settings</h12-regular>
        </div>
    </div>
    <div class="profile-container" [nbContextMenu]="userMenu">
        <div class="profile-container_image">
            <nb-user [name]="user?.name" [picture]="" size="small" [onlyPicture]="true">
            </nb-user>
        </div>
        <div [ngClass]="this.isSideBarHidden ? 'profile-container_username hide':'profile-container_username'">
            <h12-regular [color]="Colors.primaryGray4">
                {{user?.name}}</h12-regular>
        </div>
    </div>
    <ngx-app-logo></ngx-app-logo>
</div>
