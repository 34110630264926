import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'TimeFormatMS'
})
export class TimeFormatMSPipe implements PipeTransform {
  transform(value: string): string {
    const parts = value?.split(':');
    const minutes = parseInt(parts[0], 10);
    const seconds = parseInt(parts[1], 10);
    
    return `${this.padZero(minutes)}m : ${this.padZero(seconds)}s`;
  }

  private padZero(num: number): string {
    return num < 10 ? `0${num}` : num.toString();
  }
}
