import { logoutUser } from "./../../../@core/state/auth/auth.actions";
import { ResponseState } from "../../../@core/api/models/response_state";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, Subject } from "rxjs";
import {
  NbMediaBreakpointsService,
  NbMenuService,
  NbSidebarService,
  NbThemeService,
} from "@nebular/theme";
import { BaseComponent } from "../../../@core/api/base/base-component";
import { LayoutService } from "../../../@core/utils";
import { map, takeUntil } from "rxjs/operators";
import { Store } from "@ngrx/store";

@Component({
  selector: "ngx-footer",
  styleUrls: ["./footer.component.scss"],
  templateUrl: "./footer.component.html",
})
export class FooterComponent extends BaseComponent implements OnInit {
  private destroy$: Subject<void> = new Subject<void>();

  userMenu = [{ title: "Profile" }, { title: "Help" }, { title: "Log out" }];

  user: any;
  isSideBarHidden: boolean = false;

  constructor(
    private router: Router,
    private menuService: NbMenuService,
    private sideBarService: NbSidebarService,
    private themeService: NbThemeService,
    private breakpointService: NbMediaBreakpointsService,
    private store: Store<{ user: ResponseState }>
  ) {
    super();
  }

  ngOnInit() {
    this.listenUserDetails();

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService
      .onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$)
      )
      .subscribe(
        (isLessThanXl: boolean) => (this.isSideBarHidden = isLessThanXl)
      );

    this.sideBarService.onToggle().subscribe(() => {
      this.isSideBarHidden = !this.isSideBarHidden;
    });

    this.menuService.onItemClick().subscribe((item) => {
      if (item["item"].title === "Log out") {
        this.logoutUser();
      } else if (item["item"].title === "Profile") {
        this.router.navigate(["pages/profile"]);
      } else if (item["item"].title === "Help") {
        this.router.navigate(["pages/help"]);
      }
    });
  }

  listenUserDetails() {
    this.store
      .select((state) => state.user)
      .subscribe((state: ResponseState) => {
        if (state.data) {
          this.user = state.data;
        }
      });
  }

  logoutUser() {
    this.store.dispatch(logoutUser());
  }
}
