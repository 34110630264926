import { createReducer, on } from '@ngrx/store';
import { loadAllMessages, loadAllMessagesFailure, loadAllMessagesSuccess } from "./chat.actions";
import { ResponseState } from '../../api/models/response_state';

const initialState: ResponseState = {
  data: null,
  loading: false,
  error: null,
};

export const chatReducer = createReducer(
  initialState,
  on(loadAllMessages, (state) => ({
    ...state,
    loading: true,
    error: null,
    data: null,
  })),
  on(loadAllMessagesSuccess, (state, { messages }) => ({
    ...state,
    loading: false,
    error: null,
    data: messages,
  })),
  on(loadAllMessagesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error: error,
    data: null,
  })),
);
