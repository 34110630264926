import { createAction, props } from "@ngrx/store";
import { ActivityLog } from "../../api/models/data-models";

const ACTIVITY_LOGS_FETCH_REQUEST = '[Activity Logs] Activity Logs Fetch Request';
const ACTIVITY_LOGS_FETCH_SUCCESS = '[Activity Logs] Activity Logs Fetch Success';
const ACTIVITY_LOGS_FETCH_FAILURE = '[Activity Logs] Activity Logs Fetch Failure';

const ACTIVITY_LOGS_WRITE_REQUEST = '[Activity Logs] Activity Logs Write Request';
const ACTIVITY_LOGS_WRITE_SUCCESS = '[Activity Logs] Activity Logs Write Success';
const ACTIVITY_LOGS_WRITE_FAILURE = '[Activity Logs] Activity Logs Write Failure';

export const activityLogsFetchRequest = createAction(ACTIVITY_LOGS_FETCH_REQUEST, props<{ patientId: string }>());
export const activityLogsFetchSuccess = createAction(ACTIVITY_LOGS_FETCH_SUCCESS, props<{ activityLogs: ActivityLog[] }>());
export const activityLogsFetchFailure = createAction(ACTIVITY_LOGS_FETCH_FAILURE, props<{ error: string }>());

export const activityLogsWriteRequest = createAction(ACTIVITY_LOGS_WRITE_REQUEST, props<{ patientId: string, activityLog: ActivityLog }>());
export const activityLogsWriteSuccess = createAction(ACTIVITY_LOGS_WRITE_SUCCESS);
export const activityLogsWriteFailure = createAction(ACTIVITY_LOGS_WRITE_FAILURE, props<{ error: string }>());