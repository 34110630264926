export enum BUTTON_TYPE {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    TERTIARY = 'tertiary'
}

export enum BUTTON_SIZE {
    LARGE = 'large',
    MEDIUM = 'medium',
    SMALL = 'small'
}