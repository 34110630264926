import { resetPassword } from "./../../@core/state/auth/auth.actions";
import { ResponseState } from "./../../@core/api/models/response_state";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";

@Component({
  selector: "ngx-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
})
export class ResetPasswordComponent implements OnInit {
  constructor(
    private router: Router,
    private store: Store<{ resetPasswordReducer: ResponseState }>
  ) {}

  ngOnInit(): void {
    this.listenToPasswordResetState();
    this.email = "";
  }
  message: string = "";

  email: string;

  messageClass: any;

  resetPassword() {
    this.store.dispatch(
      resetPassword({
        email: this.email,
      })
    );
  }

  listenToPasswordResetState() {
    this.store
      .select((state) => state.resetPasswordReducer)
      .subscribe((state: ResponseState) => {
        if (state.data) {
          this.message = state.data.message;
          this.messageClass = state.data.status;
          if (this.messageClass === "success") {
            setTimeout(() => {
              this.router.navigate(["auth", "login"]);
            }, 1000);
          }
        }
      });
  }
}
